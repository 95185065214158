import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, IconButton, Tooltip, Modal, Button, TextField, Link } from '@material-ui/core';
import FontAwesome from '@src/Components/common/FontAwesome';
import type { Props } from './types';
import { useSnackbar } from 'notistack';
import Api from '@oneAppCore/services/Api';
import { APPROVED, CREATED, PENDING, REJECTED, EDIT } from '@oneAppCore/constants/listings';
import useSearch from '@oneAppCore/one-app/src/Components/common/containers/SearchView/hooks/useSearch';
import { convertDollarsToCents } from '@src/utils/currency';
import sListingAPI from '@oneAppCore/services/StoreListingApi';
import { BOOTSTRAP, DARKBLUES, DARKGREENS, REDS2 } from '@oneAppCore/constants/colors';
import { pick } from 'lodash';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  },
}));

const options = {
  approve: {
    successMessage: "Approved the listing Change(s)",
    errorMessage: "Error approving listing Change(s)",
    title: "Approve",
    modalContent: "This will submit selected change(s) directly to the sales channel.",
  },
  undo: {
    successMessage: "Reverted listing Change(s)",
    errorMessage: "Error approving listing Change(s)",
    title: "Undo",
    modalContent: "This will undo the change(s) selected.",
  },
  edit: {
    successMessage: "Edited listing Change(s)",
    errorMessage: "Error editing listing Change(s)",
    title: "Edit",
    modalContent: "This will Edit the change(s) selected.",
  },
  needChange: {
    successMessage: "Rejected the listing Change(s)",
    errorMessage: "Error changing status of listing Change(s)",
    title: "Reject",
    modalContent: "This will change the status of the change(s) to Rejected.",
  },
}

const modalTypeChanges = {
  REJECTED: {
    icon: 'ban',
    status: REJECTED,
    option: options.needChange,
    color: BOOTSTRAP.v5.danger.color,
    // color: 'red',
  },
  APPROVED: {
    icon: 'check',
    status: APPROVED,
    option: options.approve,
    color: BOOTSTRAP.v5.success.color,
    // color: DARKGREENS.main,
    // color: '#10960E',
  },
  CREATED: {
    icon: 'undo',
    status: CREATED,
    option: options.undo,
    color: BOOTSTRAP.v5.dark.color,
    // color: DARKBLUES.dark,
    // color: '#2B0E96',
  },
  EDIT: {
    icon: 'edit',
    status: CREATED,
    option: options.edit,
    color: BOOTSTRAP.v5.primary.color,
  },
};

function ApproveButton({ row, modalType, disabled = false, dataType = 'listing', hoverText }: Props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const modalOption = modalTypeChanges[modalType || APPROVED]?.option;
  const [message, setMessage] = useState('');
  const [edits, setEdits] = useState(null);
  const { mutate } = useSearch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setMessage('');
    setOpen(false);
  };

  const editChange = (changes: any) => { }

  const changeListingStatus = async () => {
    const status = modalType === EDIT ? row.changes?.changes?.status : modalTypeChanges[modalType || APPROVED]?.status || APPROVED
    const data = {
      status,
    };
    try {
      let updateData;
      switch (modalType) {
        case REJECTED: {
          updateData = await sListingAPI.updateSLChange(row.changes?.id, { ...data, });
          break;
        }
        case CREATED: {
          updateData = await sListingAPI.updateSLChange(row.changes?.id, { ...data, approved: false });
          break;
        }
        default: {
          updateData = await sListingAPI.updateSLChange(row.changes?.id, { ...data, approved: true });
        }
      }
      if (updateData) {
        enqueueSnackbar(modalOption.successMessage, {
          variant: 'success',
        });
        mutate();
      } else {
        enqueueSnackbar(modalOption.errorMessage, {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar(modalOption.errorMessage, {
        variant: 'error',
      });
    } finally {
      {
        handleClose();
      }
    }
  };
  const color = () => {
    let ret;
    if (disabled) {
      ret = 'gray';
    } else {
      ret = modalTypeChanges[modalType || APPROVED]?.color;
    }
    return ret;
  }

  const changeThing = async () => {
    if (dataType === 'listing') {
      return changeListingStatus();
    } else if (dataType === 'variation') {
      const { listings } = row;
      const status = modalType === EDIT ? row.changes?.changes?.status : modalTypeChanges[modalType || APPROVED]?.status || APPROVED
      const data = {
        status,
      };
      let filterFunc = (a) => a.changes.status === CREATED;
      if (modalType === CREATED) {
        filterFunc = (a) => a.changes.status === REJECTED || a.changes.status === APPROVED;
      }
      const changeIds = listings.filter(filterFunc).map(({ changes }) => changes.id);
      try {
        let updateData;
        switch (modalType) {
          case REJECTED: {
            updateData = await sListingAPI.updateSLChangeBulk({ ...data, changeIds, });
            break;
          }
          case CREATED: {
            updateData = await sListingAPI.updateSLChangeBulk({ ...data, approved: false, changeIds, });
            break;
          }
          default: {
            updateData = await sListingAPI.updateSLChangeBulk({ ...data, approved: true, changeIds, });
          }
        }
        console.log(changeIds);
        if (updateData) {
          enqueueSnackbar(modalOption.successMessage, {
            variant: 'success',
          });
          mutate();
        } else {
          enqueueSnackbar(modalOption.errorMessage, {
            variant: 'error',
          });
        }
      } catch (error) {
        enqueueSnackbar(modalOption.errorMessage, {
          variant: 'error',
        });
      } finally {
        {
          handleClose();
        }
      }
    }
  }

  const rejectedModalExtraContent = (<>
    <Grid item container xs={12} style={{ paddingLeft: 20, paddingRight: 20, justifyContent: 'center' }}>
      <TextField
        value={message}
        placeholder={'Message (optional)'}
        variant="outlined"
        onChange={(e) => setMessage(e.target.value)}
        style={{ width: '80%' }}
      />
    </Grid>
  </>);
  const editModalExtraContent = (<>
    <Grid item container xs={12} style={{ paddingLeft: 20, paddingRight: 20, justifyContent: 'center' }}>
      {Object.keys(pick(row.changes?.newValues, ['price', 'maxPrice', 'minPrice'])).map((key) => {
        return (
          <Grid item xs={4} key={`${row.changes?.id}-${key}`}>
            <TextField
              value={message}
              placeholder={formatCentsToDollarsIntl(row.changes?.newValues[key], { removeMoneySign: true })}
              variant="outlined"
              onChange={(e) => setMessage(e.target.value)}
              style={{ width: '90%' }}
            />
          </Grid>
        );
      })}
    </Grid>
  </>);

  return (
    <>
      <Grid item container justifyContent="center">
        <Tooltip title={hoverText || modalOption.title}>
          <IconButton
            disabled={disabled}
            style={{
              fontSize: '14px',
              color: color(),
              paddingLeft: '15%',
              paddingRight: '15%',
            }}
          >
            <FontAwesome
              name={modalTypeChanges[modalType || APPROVED]?.icon || 'check'}
              type="fa"
              form="fa"
              onClick={handleOpen}
            />
          </IconButton>
        </Tooltip>
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            style={{
              width: 500,
              backgroundColor: 'white',
              borderRadius: 10,
              justifyContent: 'center',
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{
                justifyContent: 'center',
                width: '100%',
                borderRadius: '10px 10px 0px 0px',
              }}
            >
              <Grid
                item
                container
                xs={12}
                style={{
                  justifyContent: 'flex-end',
                  marginTop: 10,
                  marginRight: 10,
                  marginBottom: '-40px',
                }}
              >
                <Button onClick={() => handleClose()}>
                  <FontAwesome
                    type="fa"
                    form="fa"
                    name="times"
                  />
                </Button>
              </Grid>
              <h1>Are You Sure?</h1>
            </Grid>
            <Grid item container xs={10} style={{ justifyContent: 'center' }}>
              <p
                style={{
                  fontSize: '20px',
                  textAlign: 'center',
                }}
              >
                {modalOption.modalContent}
              </p>
            </Grid>
            {modalType === REJECTED && rejectedModalExtraContent}
            {modalType === EDIT && editModalExtraContent}
            <Grid
              item
              container
              xs={12}
              style={{ padding: 20, justifyContent: 'center' }}
            >
              <Grid
                item
                container
                xs={6}
                style={{ justifyContent: 'flex-start' }}
              >
                <Link
                  // variant="contained"
                  component="button"
                  variant="body2"
                  color="secondary"
                  onClick={() => {
                    history.push(`/products/view/${row.storeProductId}`);
                  }}
                >
                  Review Product
                </Link>
              </Grid>
              <Grid
                item
                container
                xs={6}
                style={{ justifyContent: 'flex-end' }}
              >
                <Button variant="contained" color="primary" onClick={() => changeThing()}>
                  {modalOption.title}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </>
  );
}

export default ApproveButton;
