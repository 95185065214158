export const tableNames = {
  ///-------------------
  ///USERS
  ///-------------------
  users: 'users',
  roles: 'roles',
  userRoles: 'user_roles',
  userLocations: 'user_locations',
  roleCategories: 'role_categories',
  ///-------------------
  ///ORDERS
  ///-------------------
  orders: 'orders',
  orderItems: 'order_items',
  orderIssues: 'order_issues',
  orderChannelData: 'order_channel_data',
  orderItemChannelData: 'order_item_channel_data',
  orderLabels: 'order_labels',
  exportedOrders: 'exported_orders',
  ///-------------------
  ///STORES
  ///-------------------
  stores: 'stores',
  storeListings: 'store_listings' as const,
  storeListingChanges: 'store_listing_changes',
  storeProducts: 'store_products',
  variations: 'variations' as const,
  variationImages: 'variation_images',
  upcToVariation: 'upc_to_variation',
  ///-------------------
  ///FITMENT
  ///-------------------
  atvFitment: 'atv_fitments',
  atvs: 'atvs',
  vehicleFitment: 'vehicle_fitment',
  vehicleMake: 'vehicle_make',
  vehicleModel: 'vehicle_model',
  ///-------------------
  ///COMPANY
  ///-------------------
  companies: 'companies',
  storeChannels: 'store_channels',
  storeChannelNotificationSubscriptions:
    'store_channel_notification_subscriptions',
  storeChannelFeeds: 'store_channel_feeds',
  storeSuppliers: 'store_suppliers',
  manufacturerSuppliers: 'manufacturer_suppliers' as const,
  restockVirtualInventory: 'restock_virtual_inventory',
  companyIntegrations: 'company_integrations',
  ///-------------------
  ///App Logic
  ///-------------------
  apiKeys: 'api_keys',
  channels: 'channels',
  suppliers: 'suppliers',
  brands: 'brands',
  integrations: 'integrations',
  masterBrands: 'master_brands',
  brandArrayTypes: 'brand_array_types',
  channelCategories: 'channel_categories',
  itemGroups: 'item_groups',
  itemGroupContents: 'item_group_contents',
  appVars: 'app_variables',
  archiveData: 'archived_data',
  ///-------------------
  ///Discounts
  ///-------------------
  brandDiscounts: 'company_brands',
  // brandDiscounts: 'brand_discounts',
  variationDiscounts: 'variation_discounts',
  productDiscounts: 'product_discounts',
  ///-------------------
  ///App Logs
  ///-------------------
  manufacturerSupplierRecords: 'manufacturer_supplier_records',
  manufacturerSupplierRestock: 'manufacturer_supplier_internal_restock',
  purchaseOrders: 'purchase_orders',
  manufacturerQuantityLogs: 'manufacturer_supplier_quantity_logs',
  productImages: 'product_images',
  manufacturerInventoryLogs: 'manufacturer_supplier_inventory_logs',
  manufacturerInventoryLogTotals: 'manufacturer_supplier_inventory_log_totals',
  analyticsLogTotals: 'analytics_totals_logs',
  creditCardTransactions: 'credit_card_transactions',
  aiChatHistories: 'ai_chat_histories',
  ///-------------------
  ///AMAZON
  ///-------------------
  amazonBuffer: 'amazon_buffer',
  amazonFeeds: 'amazon_feeds',
  amazonBufferFeeds: 'amazon_buffer_feeds',
  ///-------------------
  ///AUDIT/LOGGING
  ///-------------------
  bulkTransactionHist: 'bulk_transaction_history',
  ///-------------------
  ///INVENTORY
  ///-------------------
  importedReports: 'imported_reports',
  lightspeedReport: 'lightspeed_report',
  shippingPackages: 'shipping_packages',
  shipRates: 'ship_rates',
};

export const schemaNames = {
  default: 'public',
  logs: 'logging',
};

export const metaColumns = [
  'updatedAt',
  'createdAt',
  'deletedAt',
  'createdBy',
  'updatedBy',
  'deletedBy',
];

export const uneditableColumns = ['id', ...metaColumns, 'companyId'];
