import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Button,
  Modal,
} from '@material-ui/core';
import { FontAwesome } from '@src/Components/common';
import VariationsApi from '@oneAppCore/services/VariationsApi';
import { mutate } from 'swr';
import AsyncSelect from '@src/Components/common/Selects/AsyncSelect';
import ProductApi from '@oneAppCore/services/ProductApi';
import { GroupForm } from '../../types';
import useVariationData from '@src/hooks/swr/Inventory/useVariationData';
import { faGlasses } from '@fortawesome/free-solid-svg-icons';

const GroupBox = ({
  index,
  group,
  onChange,
  // deleteRow,
  row,
  cellIndex,
  mutate,
}) => {
  const [options, setOptions] = useState([]);
  const [form, setForm] = useState<GroupForm>(group);
  const id = form.id;
  const { data: variations, mutate: mutateVar } = useVariationData({
    id: Number(id),
  });

  const loadProducts = async (searchFilter: string) => {
    if (searchFilter?.length < 5) return [];
    const { rows: products } = await ProductApi.getNames({
      searchFilter,
    });
    const newOptions = products.slice(0, 10).map((product) => ({
      value: product.id,
      label: product.name,
    }));
    setOptions(newOptions);
    return newOptions;
  };

  const update = <K extends keyof GroupForm>(key: K, value: GroupForm[K]) => {
    setForm((previous) => ({
      ...previous,
      [key]: value,
    }));
  };

  return (
    <>
      <Grid
        item
        container
        spacing={3}
        key={index}
        alignItems="center"
        // justify="space-between"
        style={{ padding: 20 }}
        xs={12}
      >
        <Grid item xs={2}>
          <Typography>Select a product:</Typography>
        </Grid>
        <Grid item xs={4} justify="flex-start">
          <AsyncSelect
            style={{ overflow: 'visible' }}
            id="product"
            loadOptions={loadProducts}
            value={options.find((option) => option.value === form.id)}
            onChange={(e) => update('id', e.value)}
          />
        </Grid>
        {variations?.length > 1 && (
          <Grid item xs={4}>
            <Select
              variant="outlined"
              label="Variation"
              disabled={!id}
              value={form.variationId || 0}
              style={{
                width: '100%',
                maxHeight: 40,
              }}
              onChange={(e) => {
                update('variationId', e.target.value as number);
                onChange(index, 'variationId', e.target.value);
              }}
            >
              <MenuItem value={0}>--</MenuItem>
              {variations?.map((v) => (
                <MenuItem key={v.variationId} value={v.variationId}>
                  {v.variation}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
        {variations?.length === 1 && (
          <>
            {!form.variationId ? (
              <>
                {update('variationId', variations[0].variationId)}
                {onChange(index, 'variationId', variations[0].variationId)}
              </>
            ) : (
              ''
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default GroupBox;
