import React, { useState, useEffect, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
  Paper,
  makeStyles,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  // Skeleton,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import SendIcon from '@material-ui/icons/Send';
import MenuIcon from '@material-ui/icons/Menu';
import { Chart } from 'chart.js/auto';
import FontAwesome from '@src/Components/common/FontAwesome';
import Api from '@oneAppCore/services/Api';
import { v4 } from 'uuid';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    fontFamily: 'Times New Roman, serif',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 64px)',  // Subtract AppBar height
  },
  messageArea: {
    flexGrow: 1,
    overflowY: 'auto',
    maxHeight: '65vh',
    marginBottom: theme.spacing(2),
  },
  inputArea: {
    display: 'flex',
    padding: theme.spacing(2),
  },
  input: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
  skeletonClass: {
    width: '35vw',
    height: '10vh',
    borderRadius: '0 8px 8px 8px',
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  message: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  userMessage: {
    justifyContent: 'flex-end',
    '& $messageContent': {
      // backgroundColor: theme.palette.primary.light,
      // backgroundColor: '#ecf3f8',
      backgroundColor: '#e3f0f9',
      padding: theme.spacing(1),
      borderRadius: '8px 0 8px 8px',
      textAlign: 'right',
      marginRight: theme.spacing(3),
    },
    '& $messageIcon': {
      marginRight: 0,
      marginLeft: theme.spacing(1),
    },
  },
  aiMessage: {
    justifyContent: 'flex-start',
    '& $messageContent': {
      // backgroundColor: theme.palette.secondary.light,
      // backgroundColor: '#edebf0',
      backgroundColor: '#f6f6f6',
      padding: theme.spacing(1),
      borderRadius: '0 8px 8px 8px',
      textAlign: 'left',
      marginLeft: theme.spacing(3),
    },
    '& $messageIcon': {
      marginLeft: 0,
      marginRight: theme.spacing(1),
    },
  },
  messageContent: {
    maxWidth: '80%',
    wordBreak: 'break-word',
  },
  messageIcon: {
    fontSize: '1.2rem',
  },
  chartResponse: {
    display: 'flex',
    flexDirection: 'column',
  },
  chartHeight: {
    margin: 5,
    minWidth: '30vw',
    maxHeight: '55vh',
  },
  welcomeArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  welcomeText: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  exampleCards: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
  exampleCard: {
    width: 200,
    cursor: 'pointer',
  },
  logo: {
    height: 'auto',
    width: 100,
    // width: 150,
  },
}));

const ChatbotResponse = ({ response, classes }) => {
  const [chartInstance, setChartInstance] = useState(null);
  const chartRef = useRef(null);

  useEffect(() => {
    if (response.type === 'chart' && chartInstance) {
      chartInstance.destroy();
    }

    if (response.type === 'chart') {
      const ctx = chartRef.current.getContext('2d');
      const newChartInstance = new Chart(ctx, {
        type: response.chartType,
        data: response.chartData,
        options: response.chartOptions
      });
      setChartInstance(newChartInstance);
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [response]);

  if (response.type === 'text') {
    return <Typography>{response.content}</Typography>;
  } else if (response.type === 'table') {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: response.content }} />
        <div style={{ marginTop: 10 }}><b>To summarize:</b> {response?.summary}</div>
      </div>
    );
  } else if (response.type === 'chart') {
    return (
      <Grid className={classes.chartResponse}>
        <div style={{ marginBottom: 5 }}><b>{response?.summary}</b></div>
        <div className={classes.chartHeight}>
          <canvas ref={chartRef} />
        </div>
      </Grid>
    );
  }

  return null;
};

const ChatbotPage = () => {
  const classes = useStyles();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const messageEndRef = useRef(null);
  const [sessionId, setSessionId] = useState(v4());

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const handleSendMessage = async (message) => {
    if (message.trim() === '') return;
    setInput('');

    try {
      setLoading(true);
      const userMessage = { type: 'user', content: message.trim() };
      const payload = {
        userQuestion: message.trim(),
        sessionId,
        chatHistory: messages,
      };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      const response = await Api.post(`/api/v1/oneapp-ai/get-response`, payload);

      if (!response.data) {
        throw new Error('Network response was not ok');
      }

      setMessages((prevMessages) => [
        ...prevMessages,
        { type: 'ai', content: response.data },
      ]);

      setChatHistory((prevHistory) => [
        ...prevHistory,
        { id: Date.now(), preview: message },
      ]);
    } catch (error) {
      console.error('Error:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { type: 'ai', content: { type: 'text', content: 'Sorry, an error occurred.', summary: 'Sorry, an error occurred.' } },
      ]);
    }
    setLoading(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage(input);
    }
  };

  const exampleQuestions = [
    "What were the top-selling products last month?",
    "Show me a pie chart of sales by channel for Q1.",
    "List all orders from July 10, 2024.",
  ];

  const WelcomeContent = () => (
    <div className={classes.welcomeArea}>
      {/* <Grid item>
        <img
          src={`https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/oneapp-logo1719426423419.PNG`}
          // src="/public/logo.png"
          alt="logo"
          className={classes.logo}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = '/public/logo.png';
            // currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/oa-logo.png`;
          }}
        />
      </Grid> */}
      <Typography variant="h4" className={classes.welcomeText}>
        Welcome to OneApp agent! How may I help you?
      </Typography>
      <div className={classes.exampleCards}>
        {exampleQuestions.map((question, index) => (
          <Card key={index} className={classes.exampleCard} onClick={() => handleSendMessage(question)}>
            <CardActionArea>
              <CardContent>
                <Typography variant="body2">{question}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Paper className={classes.messageArea}>
          {messages.length === 0 ? (
            <WelcomeContent />
          ) : (
            messages.map((message, index) => (
              <Grid
                key={index}
                className={`${classes.message} ${message.type === 'user' ? classes.userMessage : classes.aiMessage}`}
              >
                {message.type === 'user' ? (
                  <div className={classes.messageContent}>
                    <Typography>{message.content}</Typography>
                    {/* <FontAwesome name="user" type="fas" form="fa" className={classes.messageIcon} /> */}
                  </div>
                ) : (
                  <div className={classes.messageContent}>
                      {/* <FontAwesome name="robot" type="fas" form="fa" className={classes.messageIcon} /> */}
                    <ChatbotResponse response={message.content} classes={classes} />
                  </div>
                )}
              </Grid>
            ))
          )}
          {loading && (
            <Skeleton
              variant="rect"
              className={classes.skeletonClass}
              animation='wave'
            />
          )}
          <div ref={messageEndRef} />
        </Paper>
        <Paper className={classes.inputArea}>
          <TextField
            className={classes.input}
            variant="outlined"
            placeholder="Type your question..."
            value={input}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            disabled={loading}
          />
          <IconButton disabled={loading} color="primary" onClick={() => handleSendMessage(input)}>
            <SendIcon />
          </IconButton>
        </Paper>
      </main>
    </div>
  );
};

export default ChatbotPage;