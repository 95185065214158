import { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useMeStores from '@src/hooks/swr/useMeStores';
import useMeSuppliers from '@src/hooks/swr/useMeSuppliers';
import useMe from '@src/hooks/swr/useMe';
import useLabels from '@src/hooks/swr/useLabels';
import SearchView from '@src/Components/common/containers/SearchView';
import shipStationApi from '@oneAppCore/services/ShipStationApi';
import { getColor } from '@src/utils/colors';
import LabelApi from '@oneAppCore/services/LabelApi';
import Api from '@oneAppCore/services/Api';
import { columns } from './constants';
import MiniReports from './MiniReports';
import { FEDEX, FEDEX_CODE, PRIORITY_MAIL, UPS, UPS_CODE, USPS, USPS_CODE } from '@oneAppCore/constants/shipping';
import { addressStringFormatter } from '@src/utils/addressStringLimiter';
import useSearch from '@src/Components/common/containers/SearchView/hooks/useSearch';
import ShippingPackageApi from '@oneAppCore/services/ShippingPackageApi';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    paddingTop: theme.spacing(1),
    width: '100%',
  },
  quantity: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 20,
    width: 20,
  },
  title: {
    textOverflow: 'ellipsis',
    display: 'block',
    maxWidth: '300px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    lineHeight: 1,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  orderNumber: {
    padding: 10,
    borderRadius: 10,
    width: 100,
    height: 10,
    marginTop: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  bold: {
    fontWeight: 'bold',
    lineHeight: 0.5,
  },
  red: {
    color: getColor('dark_red', 'dark'),
    borderColor: getColor('dark_red', 'dark'),
    backgroundColor: getColor('light_red', 'light'),
    borderWidth: '1.5px',
  },
  black: {
    color: getColor('black', 'main'),
    borderColor: getColor('black', 'main'),
  },
  tablePaper: {
    width: '425px',
    borderRadius: '10px',
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    background: theme.palette.background.paper,
    boxShadow: `0px 0px 2px ${theme.palette.black.darkest}`,
  },
  tooltip: {
    maxWidth: '430px',
    borderRadius: '10px',
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    background: 'transparent',
  },
  tableCell: {
    fontWeight: 'bold',
  },
}));

interface Form {
  name?: string;
  weight?: number;
  length?: number;
  width?: number;
  height?: number;
  type?: string;
};

const defaultCarrierOptions = [
  {
    "name": USPS,
    "code": USPS_CODE,
  },
  {
    "name": FEDEX,
    "code": FEDEX_CODE,
  },
  {
    "name": UPS,
    "code": UPS_CODE,
  }
];

function InternalSearch() {
  const classes = useStyles();
  const { data: stores = [] } = useMeStores();
  const { data: suppliers = [] } = useMeSuppliers();
  const { data: labela = [], mutate } = useLabels();
  const [carrierData, setCarrierData] = useState([]);
  const [shipRates, setShipRates] = useState({});
  const activeRequest = useRef(0);

  const [packages, setPackages] = useState([]);
  const { data: me } = useMe();
  const { data } = useSearch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getCarriers = async () => {
      const data = await shipStationApi.getCarriers();
      if (data) setCarrierData(data);
    };
    // getCarriers();
    setCarrierData(defaultCarrierOptions);
    const getPackages = async () => {
      try {
        const shippingPackageResponse = await ShippingPackageApi.get();
        const shippingPackages: Form[] = (shippingPackageResponse as any)
          ?.shippingPackages;
        setPackages(shippingPackages);
      } catch (error) {
        console.error('Error fetching packages:', error);
      }
    };
    getPackages();
  }, []);

  // use effect to get rates from ship station synchronously.
  useEffect(() => {
    let isCancelled = false;
    const currentRequest = ++activeRequest.current;

    if (data) {
      callGetRates(data?.rows?.filter((row) => row?.serviceRates), currentRequest, () => isCancelled);
      callGetRates(data?.rows?.filter((row) => !row?.serviceRates), currentRequest, () => isCancelled);
    };

    return () => {
      isCancelled = true;
    };
  }, [data]);

  // function to find the lowest index.
  const findLowestCostIndex = (arr) => {
    let lowestCost = Number.POSITIVE_INFINITY;
    let lowestIndex = 0;

    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      const totalCost = element.otherCost + element.shipmentCost;

      if (totalCost < lowestCost) {
        lowestCost = totalCost;
        lowestIndex = i;
      }
    }

    return lowestIndex;
  };

  const extractOuncesFromPounds = (weight: number) => {
    let afterDecimalWeight = (weight - Math.floor(weight)).toFixed(2);
    return parseFloat(afterDecimalWeight) * 16;
  };

  // function to get rates from shipstation.
  const callGetRates = async (allData, currentRequest, isCancelled) => {
    const getShipStationData = await shipStationApi.getIntegration();
    for (const row of allData) {
      if (isCancelled()) break; // break because data is changed.
      // calling function only if we already don't have the rates.
      if (getShipStationData && !shipRates[row.id] && !row?.label) {
        const allCarriersResponse = [];
        const promiseArray = [];

        const addressArray = addressStringFormatter(
          row.addressLine1,
          row.addressLine2,
          row.addressLine3,
        );

        let weight = 0;
        let variationError = {
          error: false,
          message: '',
        };
        for (const item of row?.orderItems || []) {
          if (!item?.variationId) {
            variationError = { error: true, message: `Order with ID of ${row.id} is missing a variation Id. Cannot pull weight. This probably means there is a data inconsistency` };
            break;
            // throw `Order item with ID of ${item.id} is missing a variation Id. Cannot pull weight. This probably means there is a data inconsistency.`;
          };
          try {
            // On the assumption that weight is stored in pounds
            weight += item.weight
              ? parseFloat(item.weight) * item.quantity * 16
              : 0;
          } catch (err) {
            const error = new Error(err);
            variationError = {
              error: true,
              message: `${error.message}`
            }
          }
        };

        if (variationError.error) {
          setShipRates(prevRates => ({
            ...prevRates, [row.id]: {
              error: true,
              message: variationError.message,
            }
          }));
          continue;
        };

        const orderCosts = {
          subTotal: row?.orderItems?.reduce((accumulator, currentValue) => {
            return accumulator + currentValue?.supplierSubTotal;
          }, 0),
          itemPrice: row?.orderItems?.reduce((accumulator, currentValue) => {
            return accumulator + currentValue?.itemPrice;
          }, 0),
        };

        let updatedShipRates = false;
        if (!row.serviceRates) {
          //! getting the rates from array of constants, which needs to be changed, need to be dynamic.
          [UPS_CODE, USPS_CODE, FEDEX_CODE]?.forEach((carrier) => {
            const rateForm = {
              carrierCode: carrier,
              shipFrom: getShipStationData?.shipStationData.integrationData.shipFrom,
              shipTo: {
                name: row.shippingName,
                company: row.orderNumber.includes('# ')
                  ? row.orderNumber.split('# ')[1]
                  : row.orderNumber,
                street1: addressArray[0],
                street2: addressArray[1],
                street3: addressArray[2],
                city: row.city,
                state: row.state,
                postalCode: row.zipCode,
                country: row.country === 'USA' ? 'US' : row.country,
                phone: '',
                residential: true,
              },
              weight,
              dimensions: {
                units: 'inches',
                length: row?.packageId && row?.length ? row?.length : 1,
                width: row?.packageId && row?.width ? row?.width : 4,
                height: row?.packageId && row?.length ? row?.length : 8, // wrong.
              },
            };
            promiseArray.push(shipStationApi.getAllRates(rateForm));
          });
          for (const iterator of promiseArray) {
            const response = await iterator;
            if (response.status == 'success') {
              allCarriersResponse.push(response);
            };
          };
          updatedShipRates = true;
        } else {
          allCarriersResponse.push(...row?.serviceRates);
        };
        if (updatedShipRates) {
          try {
            await shipStationApi.updateShipRates({
              orderId: row.id,
              allCarriersResponse
            });
          } catch (error) {
            console.log("error updating ship rates", error);
          };
        };
        let cheapestCarrier = 'stamps_com';
        let serviceRates = [];
        let allRates = {};
        let badGateWayError = false;
        let lowestCarrierCost = Number.POSITIVE_INFINITY;
        allCarriersResponse.forEach((carrierResponse) => {
          if (carrierResponse.status == 'fail') {
            // error from get rates.
            badGateWayError = true;
          } else {
            let filteredServices = [];
            if (carrierResponse.carrierCode === USPS_CODE) {
              carrierResponse.serviceRates.forEach((service) => {
                if (!service.serviceName.includes(PRIORITY_MAIL) && !service.serviceName.includes('First Class Mail - Package')) {
                  filteredServices.push(service);
                };
              });
            } else {
              filteredServices = carrierResponse.serviceRates;
            }
            const sorted = filteredServices
              .map((rate) => (rate.shipmentCost + rate.otherCost).toFixed(2))
              .sort((a, b) => a - b);
            const strDex = 0;
            const endDex = sorted.length - 1;
            const minMaxRates = { min: sorted[strDex], max: sorted[endDex] };
            allRates[carrierResponse.carrierCode] = {
              minMaxRates: minMaxRates,
              serviceRates: carrierResponse.serviceRates,
            };
            if (Number(minMaxRates?.min) < lowestCarrierCost) {
              lowestCarrierCost = minMaxRates.min;
              serviceRates = filteredServices;
              cheapestCarrier = carrierResponse.carrierCode;
            }
          }
        });
        if (badGateWayError) {
          // error in getting shipping rates, moving to next one.
          continue;
        };
        const serviceRateArray = Array.isArray(serviceRates)
          ? serviceRates
          : [serviceRates];

        const lowestIndex = findLowestCostIndex(serviceRateArray);
        const selectedService = serviceRateArray[lowestIndex];
        const profit =
          orderCosts?.['itemPrice'] -
          Math.floor((orderCosts?.['itemPrice'] * 0.12)) -
          orderCosts?.['subTotal'] -
          Math.floor(serviceRateArray?.[lowestIndex]?.shipmentCost * 100) - Math.floor(serviceRateArray?.[lowestIndex]?.otherCost * 100);

        let defaultService = serviceRateArray?.[lowestIndex]?.serviceName;
        let packageCode = 'package';
        const finalRatesObj = {
          error: false,
          loading: false,
          message: 'success',
          ['carrierCode']: cheapestCarrier,
          ['carrierOptions']: carrierData?.length > 0 ? carrierData : defaultCarrierOptions,
          ['serviceOptions']: serviceRateArray,
          ['serviceCode']: serviceRateArray?.[lowestIndex]?.serviceCode,
          ['selectedServiceName']: defaultService,
          ['packageCode']: packageCode,
          ['allRates']: allRates,
          profit: profit,
          selectedShippingCost: selectedService?.shipmentCost + selectedService?.otherCost,
          weight: weight.toFixed(2),
          length: row?.orderItems.length == 1 && row?.orderItems?.[0].packageId ? row?.orderItems?.[0].length : 1,
          width: row?.orderItems.length == 1 && row?.orderItems?.[0].packageId ? row?.orderItems?.[0].width : 4,
          height: row?.orderItems.length == 1 && row?.orderItems?.[0].packageId ? row?.orderItems?.[0].height : 8,
        };
        if (!isCancelled()) {
          setShipRates(prevRates => ({ ...prevRates, [row.id]: finalRatesObj }));
        }
        // await new Promise(resolve => setTimeout(resolve, 10000));
      }
    }
  };

  const internalSuppliers = suppliers
    .filter((supplier) => supplier.internalSupplier)
    .map((supplier) => supplier.id);

  const storeOptions = stores.map((store) => ({
    label: store.name,
    value: store.id,
  }));

  const dateRangeOptions = [
    {
      label: 'Today',
      value: [
        dayjs().format('YYYY-MM-DD'),
        dayjs().add(1, 'days').format('YYYY-MM-DD'),
      ],
    },
    {
      label: 'Yesterday',
      value: [
        dayjs().subtract(1, 'days').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
      ],
    },
    {
      label: 'Last 3 Days',
      value: [
        dayjs().subtract(3, 'days').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
      ],
    },
  ];

  const statusOptions = [
    // { label: 'All', value: 'ALL' },
    { label: 'Unassigned', value: 'unassigned' },
    { label: 'Tracked', value: 'tracked' },
    { label: 'Placed', value: 'placed' },
    { label: 'Issue', value: 'issue' },
  ];

  // ! as we are getting the reprint rows from backend, letting the component get orders on focus. Need to double check if its working fine.
  return (
    <Grid item container justify="center" alignItems="center" spacing={1}>
      {internalSuppliers.length && (
        <SearchView
          url="api/v1/orders/search"
          select
          selectTypes={['batch-print']}
          carrierData={carrierData}
          mutateValue={0}
          // swrOptions={{
          //   revalidateOnFocus: true,
          //   focusThrottleInterval: 300000,
          // }}
          constantFilters={{
            status: 'unassigned',
            supplierId: internalSuppliers[0],
            purchasedAt: '--',
          }}
          filters={[
            {
              type: 'date range select',
              name: 'Purchased At',
              keyName: 'purchasedAt',
              properties: dateRangeOptions,
            },
            {
              type: 'string',
              name: 'Order Number',
              keyName: 'orderNumber',
            },
            {
              type: 'selector',
              name: 'Store',
              keyName: 'storeId',
              properties: storeOptions,
            },
            {
              type: 'selector',
              name: 'Status',
              keyName: 'status',
              properties: statusOptions,
            },
          ]}
          columns={columns(carrierData, me, classes, labela, mutate, shipRates, setShipRates, packages, setPackages)}
          // MiniReportComponent={MiniReports}
          showChannelOptions
          me={me}
        />
      )}
    </Grid>
  );
}

export default InternalSearch;
