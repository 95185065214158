import { Grid, TableCell, TableRow } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { ReactElement } from 'react';
import dayjs from 'dayjs';
import { getColor } from '@src/utils/colors';
import { convertDollarsToCents, formatCentsToDollars } from '@oneAppCore/utils/currency';

export const issueColumns = ['issueUsername', 'issueType'];

export const columns = [
  /* {
    id: 2,
    title: 'Cost:',
    key: 'cost',
    customComponent: function CostOfItem({ order, itemIndex }): ReactElement {
      const oi = order.orderItems[itemIndex];

      const formatCost = (inputCost: string | number | null) => {
        let returnedValue = 'N/A';
        if (typeof inputCost === 'number') {
          returnedValue = (inputCost / 100).toFixed(2);
        } else if (typeof inputCost === 'string') {
          returnedValue = parseFloat(inputCost).toFixed(2);
        }
        return returnedValue;
      };
      return (
        <>
          <Grid container>
            <Grid item container xs={6}>
              <Typography style={{ fontWeight: 600, fontSize: '1.15rem' }}>
                Cost:
              </Typography>
            </Grid>

            <Grid item container xs={6}>
              <Typography>
                <Grid item container xs={12}>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: '1.15rem' }}>
                      Shipping:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: '1.15rem' }}>
                      ${formatCost(oi.supplierShippingCost)}
                    </Typography>
                  </Grid>
                  <br />
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: '1.15rem' }}>
                      Tax:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: '1.15rem' }}>
                      ${formatCost(oi.supplierTax)}
                    </Typography>
                  </Grid>
                  <br />
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: '1.15rem' }}>
                      Subtotal:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>${formatCost(oi.supplierSubTotal)}</Typography>
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    },
  }, */
  {
    id: 3,
    title: 'Order Total:',
    key: 'price',
    align: 'left',
    customComponent: function SalePrice({
      order,
      itemIndex,
      supplierRadioSelection,
      totals,
    }): ReactElement {
      const { orderItems, price, channelId } = order;
      const centsPrice = convertDollarsToCents(price);
      const totalObj = totals?.find(
        (t) => t?.supplier === supplierRadioSelection,
      );
      const totalCost = order.supplierShippingCost && orderItems.every((item) => item.supplierSubTotal)
        ? orderItems.reduce((acc, curr) => acc + curr.supplierSubTotal, 0)
        : orderItems.length === 1 && totalObj
          ? totalObj.total
          : 0;

      let totalShipPrice = 0;
      let totalPrice = order.orderItems?.reduce(
        (acc, item) => {
          totalShipPrice += (item?.shippingPrice || 0);
          return acc + item.itemPrice;
        },
        0,
      );
      const feeFactor =
        channelId === 1
          ? 0.12
          : channelId === 2
            ? 0.11
            : channelId === 3
              ? 0.13
              : 0.12;
      const totalFees =
        order.channelFee
          ? order.channelFee
          : order?.orderItems?.every((e) => e.itemFee)
            ? order.orderItems.reduce((acc, item) => acc + item.itemFee, 0)
            : Math.floor(totalPrice * feeFactor);
      totalPrice += totalShipPrice;
      const profit = totalCost + (order?.supplierShippingCost || totalObj?.supplierShippingCost || 0)
        ? centsPrice - totalFees - (totalCost + order?.supplierShippingCost || totalObj?.supplierShippingCost || 0)
        : 0;
      const profitTextColor =
        profit && profit > 0
          ? getColor('green', 'main')
          : getColor('red', 'main');

      if (totalShipPrice === 0) {
        totalShipPrice = order?.supplierShippingCost || totalObj?.supplierShippingCost || 0;
      }
      return (
        <Grid container xs={12}>
          <Grid justifyContent='flex-start' item container xs={6}>
            <Typography style={{ fontWeight: 600, fontSize: '1.15rem', whiteSpace: 'nowrap' }}>
              Order Total:
            </Typography>
          </Grid>
          <Grid justifyContent='flex-start' item container direction="column" xs={6}>
            <Grid item style={{ whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography style={{ marginRight: '.5rem' }}>Total: </Typography>
              <Typography style={{ fontWeight: 'bold', color: getColor('green', 'darkest') }}>${price.toFixed(2)}</Typography>
            </Grid>
            <Grid item style={{ whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography style={{ marginRight: '.15rem' }}>Items: </Typography>
              <Typography style={{ fontWeight: 'bold' }}>{formatCentsToDollars(totalCost)}</Typography>
            </Grid>
            <Grid item style={{ whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography style={{ marginRight: '.6rem' }}>Ship:</Typography>
              <Typography style={{ fontWeight: 'bold' }}>{formatCentsToDollars(totalShipPrice)}</Typography>
            </Grid>
            <Grid item style={{ whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography style={{ marginRight: '.9rem' }}>Fee:</Typography>
              <Typography style={{ fontWeight: 'bold' }}>{formatCentsToDollars(totalFees)}</Typography>
            </Grid>
            <Grid item style={{ whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography style={{ marginRight: '.1rem' }}>Profit:</Typography>
              <Typography style={{ fontSize: '1rem', fontWeight: 'bold', color: profitTextColor, marginLeft: '4px' }}>
                {`${formatCentsToDollars(profit)}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    },
  },
  {
    id: 4,
    title: 'Ch. Order #:',
    key: 'orderNumber',
    align: 'left',
  },
  {
    id: 1,
    title: 'Part Number:',
    key: 'listingSku',
    align: 'left',
  },
  {
    id: 5,
    title: 'Supp. Order #:',
    key: 'supplierOrderNo',
    align: 'left',
  },
  {
    id: 6,
    title: 'Tracking #:',
    key: 'supplierTrackingNo',
    align: 'left',
  },

  {
    id: 7,
    title: 'Purchase Date:',
    key: 'purchaseDate',
    align: 'left',
    customComponent: function purchaseDate({ order, itemIndex }): ReactElement {
      return (
        <Grid container>
          <Grid item container xs={6}>
            <Typography style={{ fontWeight: 600, fontSize: '1.14rem' }}>
              Purchased At:
            </Typography>
          </Grid>
          <Grid item container xs={6}>
            <Typography style={{ fontSize: '1.15rem' }}>
              {dayjs(order.purchaseDate)
                .tz(dayjs.tz.guess())
                .format('MM/DD/YYYY hh:mma z')}
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    id: 8,
    title: 'Buyer Name:',
    key: 'shippingName',
    align: 'left',
  },
  {
    id: 9,
    title: 'Shipping Address:',
    key: 'addressLine1',
    align: 'left',
    customComponent: function ShippingAddress({
      order,
      itemIndex: number,
    }): ReactElement {
      return (
        <Grid container>
          <Grid item container xs={6}>
            <Typography style={{ fontWeight: 600, fontSize: '1.14rem' }}>
              Shipping <br />
              Address:
            </Typography>
          </Grid>
          <Grid item container xs={6}>
            <Typography style={{ fontSize: '1.15rem' }}>
              {order.addressLine1} {order.addressLine2} {order.addressLine3}
              {order.city}, {order.state} {order.zipCode} {order.country}
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    id: 10,
    title: 'Sales Channel:',
    key: 'channel',
    align: 'left',
  },
  {
    id: 11,
    title: 'Order Status:',
    key: 'orderStatus',
    align: 'left',
  },
  {
    id: 12,
    title: 'Placed By:',
    key: 'placedByUsername',
    align: 'left',
    customComponent: function placedBy({
      order,
      itemIndex: number,
    }): ReactElement {
      return (
        <Grid container>
          <Grid item container xs={6}>
            <Typography style={{ fontWeight: 600, fontSize: '1.14rem' }}>
              Placed By:
            </Typography>
          </Grid>
          <Grid item container xs={6}>
            <Typography style={{ fontSize: '1.15rem' }}>
              {order?.placedByUsers?.toString()}
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    id: 13,
    title: 'Tracked By:',
    key: 'trackedByUsername',
    align: 'left',
    customComponent: function trackedBy({
      order,
      itemIndex: number,
    }): ReactElement {
      return (
        <Grid container>
          <Grid item container xs={6}>
            <Typography style={{ fontWeight: 600, fontSize: '1.14rem' }}>
              Tracked By:
            </Typography>
          </Grid>
          <Grid item container xs={6}>
            <Typography style={{ fontSize: '1.15rem' }}>
              {order?.trackedByUsers?.toString()}
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    id: 14,
    title: 'Issue Type:',
    key: 'issueType',
    align: 'left',
  },
  {
    id: 15,
    title: 'Issue Created By:',
    key: 'issueUsername',
    align: 'left',
  },
];
