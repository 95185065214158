import type { Tint, Color } from '@oneAppCore/types/commonTypes';
import {
  ISSUE,
  TRACKED,
  PLACED,
  UNASSIGNED,
  COMPLETED,
  CANCELLED,
  FAILED,
  PENDING,
  REFUNDED,
} from '@oneAppCore/constants/orders';

import { getColor } from './colors';

type colorAndTint = {
  color: Color;
  tint: Tint;
};

type pieve = {
  [ISSUE]?: colorAndTint;
  [TRACKED]?: colorAndTint;
  [PLACED]?: colorAndTint;
  [UNASSIGNED]?: colorAndTint;
  [COMPLETED]?: colorAndTint;
  [CANCELLED]?: colorAndTint;
  [FAILED]?: colorAndTint;
  [PENDING]?: colorAndTint;
  [REFUNDED]?: colorAndTint;
};

const defaultStatusColors: pieve = {
  ISSUE: { color: 'red', tint: 'main' },
  TRACKED: { color: 'blue', tint: 'main' },
  COMPLETED: { color: 'green', tint: 'main' },
  PLACED: { color: 'orange', tint: 'main' },
  UNASSIGNED: { color: 'grey', tint: 'main' },
};

export const orderStatusColors = (
  status: string,
  defaultColorAndTint: colorAndTint = { color: 'grey', tint: 'main' },
  colorMatches: pieve = defaultStatusColors,
) => {
  const defaultColor = getColor(
    defaultColorAndTint.color,
    defaultColorAndTint.tint,
  );
  if (colorMatches[status]) {
    const color = colorMatches[status];
    return getColor(color.color, color.tint);
  } else {
    return defaultColor;
  }
};

const defaultProfitColors: colorAndTint[] = [
  { tint: 'main', color: 'red' },
  { tint: 'main', color: 'orange' },
  { tint: 'main', color: 'green' },
  { tint: 'main', color: 'dark_green' },
];
export const orderProfitColors = (
  input: number = 0,
  limits: number[] = [0, 5],
  limitColors: colorAndTint[] = defaultProfitColors,
): string => {
  const defaultColor = limitColors[limits.length];

  let returnedColor: string = getColor(defaultColor.color, defaultColor.tint);
  let hasColor = false;
  for (let lindex = 0; lindex < limits.length; lindex++) {
    const color = limitColors[lindex];
    if (!hasColor) {
      if (input <= limits[lindex]) {
        hasColor = true;
        returnedColor = getColor(color.color, color.tint);
      }
    }
  }
  return returnedColor;
};
