import { Table, Grid, CircularProgress } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import SearchHeader from './SearchHeader';
import SearchBar from './SearchBar';
import SearchBody from './SearchBody';
import SearchPaginator from './SearchPaginator';

import type { Props } from './types';
import ChannelOptions from '../ChannelOptions';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    margin: theme.spacing(3, 0),
  },
}));

function SearchTable({
  columns,
  childColumns,
  filters,
  supportsDownload,
  constantFilters,
  exportOptions,
  selectedRows,
  openRows,
  showChannelOptions,
  select,
  toggleRow,
  selectRow,
  deselectRow,
  selectAllRows,
  deselectAllRows,
  selectTypes,
  url,
  carrierData,
  searchData,
  me,
  // resetPagination,
  swrOptions = {
    revalidateOnFocus: true,
    focusThrottleInterval: 3500,
  },
  customSearchBarComponents,
  selectedFilterValues,
  setSelectedFilterValues,
}: Props) {
  const classes = useStyles();
  const [resetPagination, setResetPagination] = useState(false);

  return (
    <>
      {/* {showChannelOptions && <ChannelOptions />} */}
      <SearchPaginator
        showChannelOptions={showChannelOptions}
        swrOptions={swrOptions}
        resetPagination={resetPagination}
        setResetPagination={setResetPagination}
        siblingCount={1}
        boundaryCount={2}
      />
      <Paper className={classes.root}>
        <Grid container item xs={12}>
          <SearchBar
            customComponents={customSearchBarComponents}
            filters={filters}
            constantFilters={constantFilters}
            supportsDownload={supportsDownload}
            showChannelOptions={showChannelOptions}
            exportOptions={exportOptions}
            selectedRows={selectedRows}
            select={select}
            selectTypes={selectTypes}
            deselectAllRows={deselectAllRows}
            carrierData={carrierData}
            selectRow={selectRow}
            me={me}
            resetPagination={resetPagination}
            setResetPagination={setResetPagination}
            selectedFilterValues={selectedFilterValues}
            setSelectedFilterValues={setSelectedFilterValues}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ overflowX: 'scroll', overflowY: 'auto', maxHeight: 600 }}
        >
          {searchData ? (
            <Table
              style={{
                minWidth: '100%',
              }}
              stickyHeader={true}
            >
              <SearchHeader
                columns={columns}
                childColumns={childColumns}
                select={select}
                selectedRows={selectedRows}
                selectAllRows={selectAllRows}
                deselectAllRows={deselectAllRows}
                swrOptions={swrOptions}
                selectedFilterValues={selectedFilterValues}
              />
              <SearchBody
                columns={columns}
                childColumns={childColumns}
                select={select}
                toggleRow={toggleRow}
                selectRow={selectRow}
                deselectRow={deselectRow}
                selectedRows={selectedRows}
                openRows={openRows}
                swrOptions={swrOptions}
              />
            </Table>
          ) : (
            <Grid
              item
              container
              xs={12}
              justify="center"
              style={{
                marginTop: 20,
                marginBottom: 20,
                justifyContent: 'center',
              }}
            >
              <CircularProgress color="primary" />
            </Grid>
          )}
        </Grid>
      </Paper>
      <SearchPaginator
        showChannelOptions={false}
        swrOptions={swrOptions}
        resetPagination={resetPagination}
        setResetPagination={setResetPagination}
        siblingCount={1}
        boundaryCount={2}
      />
    </>
  );
}

export default SearchTable;
