import {
  Typography,
  Grid,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
} from '@material-ui/core';
import type { ListingConstants, ListingEdit } from './types';
import {
  ACTIVE,
  DRAFT,
  INACTIVE,
  PENDING,
} from '@oneAppCore/constants/listings';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';

export const listingTable: ListingConstants[] = [
  {
    title: 'Store',
    keyName: 'store',
    customComponent: function Store(row) {
      return <Typography>{row.store}</Typography>;
    },
  },
  {
    title: 'Channel',
    keyName: 'channel',
    customComponent: function Icon(row) {
      return (
        <Grid item xs={4}>
          <img
            width={35}
            height={35}
            style={{ borderRadius: 10 }}
            src={
              row.icon != null
                ? row.icon
                : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
            }
          />
        </Grid>
      );
    },
  },
  {
    title: 'Listing Sku',
    keyName: 'listingSku',
    customComponent: function Listing(row) {
      const handleClick = () => {
        localStorage.setItem('listingSku', row?.listingSku);
      };
      return (
        <>
          <Tooltip title={'View listing'}>
            <Link href={`/listings/list`} onClick={handleClick}>
              {row.listingSku}
            </Link>
          </Tooltip>
        </>
      );
    },
  },
  {
    title: 'Min Price',
    keyName: 'minPrice',
    customComponent: function minPrice(row) {
      return (
        <div>${row.minPrice % 1 === 0 ? row.minPrice / 100 : row.minPrice}</div>
      );
    },
  },
  {
    title: 'Max Price',
    keyName: 'maxPrice',
    customComponent: function minPrice(row) {
      return (
        <div>${row.maxPrice % 1 === 0 ? row.maxPrice / 100 : row.maxPrice}</div>
      );
    },
  },
  {
    title: 'Price',
    keyName: 'price',
    customComponent: function costData(row) {
      return <div>${row.price % 1 === 0 ? row.price / 100 : row.price}</div>;
    },
  },
  {
    title: 'Cost',
    keyName: 'cost',
    customComponent: function Cost(row) {
      return (
        <Tooltip
          style={{
            width: '425px',
            borderRadius: '10px',
            justifyContent: 'flex-start',
          }}
          title={
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Supplier</TableCell>
                    <TableCell>Cost</TableCell>
                    <TableCell>Act. Ship</TableCell>
                    <TableCell>Est. Ship</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody></TableBody>
              </Table>
            </TableContainer>
          }
        >
          <Grid item>
            <Typography>{row.cost}</Typography>
          </Grid>
        </Tooltip>
      );
    },
  },
  {
    title: 'Status',
    keyName: 'status',
    editable: false,
    customComponent: function status(row) {
      const getColor = (status) => {
        switch (status.toUpperCase()) {
          // case 'inactive':
          //   return '#696969';
          case INACTIVE:
            return '#696969';
          // case 'pending':
          //   return '#EED202';
          case PENDING:
            return '#EED202';
          // case 'active':
          //   return '#4BB543';
          case ACTIVE:
            return '#4BB543';
          case DRAFT:
            return '#FF9F43';
          default:
            return '#696969';
        }
      };
      return (
        <Typography
          style={{
            color: '#FFF',
            textAlign: 'center',
            backgroundColor: getColor(row.status),
            borderRadius: 10,
            padding: 3,
            // maxWidth: '100px',
          }}
        >
          {row.status}
        </Typography>
      );
    },
  },
  {
    title: 'Edit',
    keyName: 'edit',
  },
];

export const channelOptions = [
  {
    id: 1,
    name: 'Amazon',
  },
  {
    id: 2,
    name: 'eBay',
  },
  {
    id: 3,
    name: 'Walmart',
  },
  {
    id: 4,
    name: 'Woocommerce',
  },
];

export const editRows: ListingEdit = [
  { keyName: 'storeChannel', dropdown: true, values: ['dzone', 'ads'] },
  {
    keyName: 'channel',
    dropdown: true,
    values: ['Amazon', 'eBay', 'Walmart', 'Woocommerce'],
  },
  { keyName: 'listingSku', dropdown: false },
  { keyName: 'price', dropdown: false },
  {
    keyName: 'status',
    dropdown: true,
    values: ['active', 'inactive', 'pending'],
  },
  {
    keyName: 'edit',
    dropdown: false,
  },
];
