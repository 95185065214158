import { useState, useEffect, useMemo } from 'react';
import useSuppliers from '@src/hooks/swr/useSuppliers';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Theme,
  SwipeableDrawer,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import recoilSearch from '@src/Components/common/containers/SearchView/hooks/useSearchOptions/index';
import useSearch from '@src/Components/common/containers/SearchView/hooks/useSearch';
import { searchOptionsDefault } from '@src/Components/common/containers/SearchView/hooks/useSearchOptions/constants';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SearchPaginator from '@src/Components/common/containers/SearchView/SearchTable/SearchPaginator';
import SearchBar from '@src/Components/common/containers/SearchView/SearchTable/SearchBar';
import { Backdrop } from '@src/Components/common/Loaders';
import DescIcon from '@src/Components/common/containers/SearchView/SearchTable/SearchHeader/DirectionIcons/DescIcon';
import AscIcon from '@src/Components/common/containers/SearchView/SearchTable/SearchHeader/DirectionIcons/AscIcon';
import { FontAwesome } from '@src/Components/common';
// import MakeDrawer from './FitmentDrawers/MakeDrawer';
// import ModelDrawer from './FitmentDrawers/ModelDrawer';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import dateUtil from '@src/utils/dates';

import { columns } from './constants';
import SearchView from '@src/Components/common/containers/SearchView';
import { formatCentsToDollars } from '@src/utils/currency';
import { getStartLetters } from '@oneAppCore/utils/formatters';
import { SUPP_UN } from '@oneAppCore/constants/suppliers';
import ClosePoButton from './Modals/ClosePoButton';
import CancelItem from './Modals/CancelItem';

function InboundPage() {
  const { data: suppliers = [] } = useSuppliers();
  const supplierOptions = useMemo(
    () =>
      suppliers.map((company) => ({
        label: company.name,
        value: company.id,
      })),
    [suppliers],
  );
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      width: '100%',
      margin: theme.spacing(3, 0),
    },
    headerColor: {
      border: 0,
      margin: 5,
    },
    fontColor: {
      color: '#FFF',
      backgroundColor: '#6e6b7b',
      fontSize: 10,
    },
    tableContainer: {
      borderRadius: 0,
      overflowX: 'scroll',
      overflowY: 'auto',
      maxHeight: 600
    },
    collapseTableContaainder: {
      maxHeight: 550,
      maxWidth: '100%',
      overflowX: 'hidden',
      margin: 10,
      borderRadius: 5,
      border: '1px solid #6e6b7b',
    },
    cellContentWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    filter: {
      width: 20,
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(0.5),
    },
    drawerPaper: {
      width: 400,
      padding: theme.spacing(2),
    },
    completeColor: {
      backgroundColor: 'rgba(97,185,137, .2) !important',
    },
    inboundColor: {
      backgroundColor: 'rgba(245,173,5, .1) !important',
    },
    dueColor: {
      backgroundColor: 'rgba(207,131,132, .2) !important',
    },
    extraColor: {
      backgroundColor: 'rgba(7,204,227, .1) !important',
    },
    cancelledColor: {
      backgroundColor: 'rgba(200,200,200, 1) !important',
    },
    closedColor: {
      backgroundColor: 'rgba(222,222,222, .5) !important',
    },
  }));

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [resetPagination, setResetPagination] = useState(false);
  const [drawerType, setDrawerType] = useState('');
  const [selectedFilterValues, setSelectedFilterValues] = useState({});

  const [, setSearchOptions] = recoilSearch.useState();
  const { data } = useSearch();
  const url = '/api/v1/manufacturer-suppliers-restock/inboundPage';
  const classes = useStyles();

  const toggleOpen = (drawerType: string = 'editModel') => {
    setDrawerType(drawerType);
    setDrawerOpen(true);
  };
  const close = () => {
    setDrawerType('');
    setDrawerOpen(false);
  };

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    return (
      <>
        <TableRow
          key={row?.poId}
          className={
            row?.isClose ? classes.closedColor :
              row?.poStatus == 'Complete' ? classes.completeColor :
                row?.poStatus == 'Inbound' ? classes.inboundColor :
                  row?.poStatus == 'Extra' ? classes.extraColor :
                    classes.dueColor
          }
        >
          <TableCell align='center'>
            {dateUtil.getFormattedDate({ date: row?.createdAt })}
          </TableCell>
          <TableCell align='center'>{row?.purchasedBy}</TableCell>
          <TableCell align="center">
            {row?.poNumber}
          </TableCell>
          <TableCell align="center" style={{ color: 'green' }}>
            {formatCentsToDollars(row?.totalCost)}
          </TableCell>
          <TableCell align="center" style={{ color: 'green' }}>
            {row?.estTotalCost == 0 ? '$0' : formatCentsToDollars(row?.estTotalCost)}
          </TableCell>
          <TableCell align="center">
            {row?.purchased}
          </TableCell>
          <TableCell align="center">
            {row?.recieved ? row?.recieved : 0}
          </TableCell>
          {/* <TableCell align="center">
            <div>
              <Tooltip title="Edit Fitment">
                <IconButton
                  onClick={() => {
                    setSelectedRow(row);
                    toggleOpen('editModel');
                  }}
                >
                  <EditTwoToneIcon color="primary" fontSize="default" />
                </IconButton>
              </Tooltip>
            </div>
          </TableCell> */}
          <TableCell align="center">
            <Grid item container style={{ justifyContent: 'center' }}>
              {getStartLetters(row?.supplierName, { caps: true }) || SUPP_UN}
            </Grid>
          </TableCell>
          <TableCell align="center">
            {row?.isClose && <Tooltip title={'#PO Closed'}>
              <span><u>Closed</u></span>
            </Tooltip>}
            {!row?.isClose && <ClosePoButton
              internalRestockData={row?.internalRestockData?.filter((item) => (item?.qty || 0) > (item?.recieved || 0))}
              poId={row?.poId} poNumber={Number(row?.poNumber)}
            />}
          </TableCell>
          <TableCell align="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <TableContainer className={classes.collapseTableContaainder}>
                  <Table size="small" aria-label="purchases" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.fontColor}>
                          MPN
                        </TableCell>
                        <TableCell align="center" className={classes.fontColor}>
                          ORDERED
                        </TableCell>
                        <TableCell align="center" className={classes.fontColor}>
                          RECEIVED
                        </TableCell>
                        <TableCell align="center" className={classes.fontColor}>
                          Status
                        </TableCell>
                        <TableCell align="center" className={classes.fontColor}>
                          Cancel Item
                        </TableCell>
                        {/* <TableCell align="left" className={classes.fontColor}>
                          Edit
                        </TableCell> */}
                      </TableRow>
                    </TableHead>
                    {row?.internalRestockData?.[0] != null && (
                      <TableBody>
                        {row?.internalRestockData?.map((item) => (
                          <TableRow
                            key={item?.id}
                            className={
                              item?.inboundStatus == 'Complete' ? classes.completeColor :
                                item?.inboundStatus == 'Cancelled' ? classes.cancelledColor :
                                  item?.inboundStatus == 'Inbound' ? classes.inboundColor :
                                    item?.inboundStatus == 'Extra' ? classes.extraColor :
                                      classes.dueColor
                            }
                          >
                            <TableCell component="th" scope="row">
                              {item?.mpn}
                            </TableCell>
                            <TableCell align="center">{item?.qty ? item?.qty : 0}</TableCell>
                            <TableCell align="center">{item?.recieved ? item?.recieved : 0}</TableCell>
                            <TableCell align="center">{item?.inboundStatus}</TableCell>
                            <TableCell align="center">
                              <CancelItem
                                mpnId={item?.mpnId}
                                internalRestockId={item?.internalRestockId}
                                mpn={item?.mpn}
                                purchased={item?.qty ? Number(item?.qty) : 0}
                                received={item?.recieved ? Number(item?.recieved) : 0}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                    {row?.internalRestockData?.[0] == null && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            className={classes.headerColor}
                            align="center"
                          >
                            This PO# has no items attached to it.
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  useEffect(() => {
    if (url) {
      setSearchOptions({
        ...searchOptionsDefault,
        url,
      });
    }
  }, [url]);

  return (
    <>
      <SwipeableDrawer
        open={drawerOpen}
        onClose={close}
        onOpen={() => null}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
      >
        {/* {drawerType === 'editModel' && (
          <ModelDrawer close={close} selectedRow={selectedRow} />
        )}
        {drawerType === 'make' && <MakeDrawer close={close} />}
        {drawerType === 'model' && <ModelDrawer close={close} />} */}
      </SwipeableDrawer>
      {data ? (
        <>
          <SearchPaginator
            showChannelOptions={false}
            resetPagination={resetPagination}
            setResetPagination={setResetPagination}
          />
          <Paper className={classes.root}>
            <SearchBar
              filters={[
                {
                  type: 'number',
                  name: 'PO#',
                  keyName: 'poNumber',
                },
                {
                  type: 'selector',
                  name: 'Supplier',
                  keyName: 'supplierId',
                  properties: supplierOptions,
                },
                {
                  type: 'selector',
                  name: 'Status',
                  keyName: 'poStatus',
                  properties: [
                    { label: 'Closed', value: 'closed' },
                    { label: 'Complete', value: 'complete' },
                    { label: 'Extra', value: 'extra' },
                    { label: 'Inbound', value: 'inbound' },
                    { label: 'Past Due', value: 'pastDue' },
                  ],
                },
              ]}
              resetPagination={resetPagination}
              setResetPagination={setResetPagination}
              selectedFilterValues={selectedFilterValues}
              setSelectedFilterValues={setSelectedFilterValues}
            />
            <Grid container item xs={12} style={{ overflowX: 'initial' }}>
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table stickyHeader aria-label="collapsible table">
                  <TableHead>
                    <TableRow className={classes.headerColor}>
                      <TableCell align="center">
                        {/* <div className={classes.cellContentWrapper}>
                          <div className={classes.filter}>
                            <AscIcon keyName="model" />
                            <DescIcon keyName="model" />
                          </div>
                        </div> */}
                        PURCHASED ON
                      </TableCell>
                      <TableCell align="center">PURCHASED BY</TableCell>
                      <TableCell align="center">PO#</TableCell>
                      <TableCell align="center">Cost</TableCell>
                      <TableCell align="center">EST. COST</TableCell>
                      <TableCell align="center">ORDERED</TableCell>
                      <TableCell align="center">RECIEVED</TableCell>
                      <TableCell align="center">SUPPLIER</TableCell>
                      <TableCell align="center">ACTIONS</TableCell>
                      <TableCell align="center">VIEW ORDER</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.rows?.map((row) => (
                      <Row key={row.id} row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Paper>
          <SearchPaginator
            showChannelOptions={false}
            resetPagination={resetPagination}
            setResetPagination={setResetPagination}
          />
        </>
      ) : (
        <>
          <Grid container item xs={12} justify="center" alignItems="center">
            <Grid
              item
              container
              xs={5}
              md={3}
              lg={1}
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <CircularProgress color="primary" />
            </Grid>
            </Grid>
        </>
      )}
    </>
  );
}

export default InboundPage;
