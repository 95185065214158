import { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  SwipeableDrawer,
  Grid,
  Button,
  TextField,
  Typography,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import SupplierOrderButton from '@src/Components/Orders/SupplierOrderButton';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { Props } from './types';
import { columns } from './constants';
import useInventoryScanHist from '@src/hooks/swr/Inventory/useInventoryScanHistory';
import dayjs from 'dayjs';
import { numberGrouper } from '@oneAppCore/utils/formatters';
import Pagination from '@material-ui/lab/Pagination';

const drawerWidth = 400;
const mobileWidth = 260;
interface StyleProps {
  mobile: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
      width: '400px',
    },
    [theme.breakpoints.down('md')]: {
      width: '350px',
    },
    padding: 20,
    justifyContent: 'flex-start',
  },
  title: { paddingTop: theme.spacing(1) },
  root: {
    display: 'flex',
    flexBasis: 0,
    margin: theme.spacing(3, 0),
  },

  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

export default function ViewInventoryDrawer({
  isDrawerOpen,
  setDrawerOpen,
  row,
  mobile,
}: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [scanList, setScanList] = useState({});
  const [scanArray, setScanArray] = useState<any[]>([]);
  const [scanTotalArray, setScanTotalArray] = useState<any[]>([]);
  const [logList, setLogList] = useState<any[]>([]);
  const [fitmentList, setFitmentList] = useState({});
  const [scanListKeys, setScanListKeys] = useState([]);
  const [scanPage, setScanPage] = useState(1);
  const [scanPageCount, setScanPageCount] = useState(10);
  const [fitListKeys, setFitListKeys] = useState([]);
  const [fitPage, setFitPage] = useState(1);
  const [fitPageCount, setFitPageCount] = useState(10);

  const classes = useStyles({ mobile });

  const {
    data: { scanned, purchased, fitted, ordered, logged },
  } = useInventoryScanHist(row.id);

  const noTypes = ['Inbound'];
  const noBeforeTypes = ['Override'];
  const qtyMinus = ['Order'];


  useEffect(() => {
    setIsLoading(false);
    const obj: any = {};
    let ary: any[] = [];
    // const scanListFunc = (sc) => {
    //   const { type, date } = sc;
    //   const name = `${date}-${type}`;
    //   if (!obj[name]) obj[name] = sc;
    // }
    ary = ary.concat(scanned)
    ary = ary.concat(purchased)
    ary = ary.concat(ordered)
    // scanned.forEach(scanListFunc);
    // purchased.forEach(scanListFunc);
    // ordered.forEach(scanListFunc);
    setLogList(logged);

    // setScanList(obj);
    const sortedScanArray = (ary.sort((a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf()).reverse());
    /**/
    const filteredScanArray = sortedScanArray.filter((_, index) => index < scanPageCount * scanPage && index >= scanPageCount * (scanPage - 1));
    /**/
    setScanTotalArray(sortedScanArray);
    setScanArray(filteredScanArray);
    // const scanKeys = Object.keys(obj).sort().reverse();
    const fitKeys = Object.keys(obj).sort().reverse();
    // const filteredScanKeys = scanKeys.filter((key, index) => index < scanPageCount * scanPage && index >= scanPageCount * (scanPage - 1));
    const filteredFitKeys = fitKeys.filter((key, index) => index < fitPageCount * fitPage && index >= fitPageCount * (fitPage - 1));
    // setScanListKeys(filteredScanKeys);
    setFitListKeys(filteredFitKeys);
  }, [scanned, purchased, ordered]);

  useEffect(() => {
    const obj = {};
    fitted.forEach((fit) => {
      const { make, model, years } = fit;
      if (!obj[make]) obj[make] = {};
      if (!obj[make][model]) obj[make][model] = numberGrouper({ years, formatResult: true });
    })

    setFitmentList(obj);
  }, [fitted])

  useEffect(() => {
    // const keys = Object.keys(scanList).sort().reverse();
    const filtered = scanTotalArray.filter((_, index) => index < scanPageCount * scanPage && index >= scanPageCount * (scanPage - 1));
    // const filteredKeys = keys.filter((_, index) => index < scanPageCount * scanPage && index >= scanPageCount * (scanPage - 1));
    // const filteredKeys = keys.filter((key, index) => index < pageCount * page && index >= pageCount * (page - 1))
    // setScanListKeys(filteredKeys);
    setScanArray(filtered);
  }, [scanPage]);
  useEffect(() => {
    const keys = Object.keys(fitmentList).sort().reverse();
    const filteredKeys = keys.filter((key, index) => index < fitPageCount * fitPage && index >= fitPageCount * (fitPage - 1));
    // const filteredKeys = keys.filter((key, index) => index < pageCount * page && index >= pageCount * (page - 1))
    setFitListKeys(filteredKeys);
  }, [fitPage]);

  return (
    <>
      <SwipeableDrawer
        open={isDrawerOpen}
        onClose={() => setDrawerOpen()}
        onOpen={() => null}
        classes={{ paper: classes.drawerPaper }}
        anchor="right"
      >
        <Grid container>
          <Grid container alignItems="center" justify="center">
            <Typography
              variant="h4"
              color="primary"
              style={{ marginTop: 15, marginBottom: 15 }}
            >
              Item Details
            </Typography>
          </Grid>
          {columns.map((item, i) => {
            if (item.customComponent) {
              const divider = (<Grid item xs={12}><Divider className={classes.divider} /></Grid>);
              return (
                <>
                  <item.customComponent row={row} />
                  {i < columns.length - 1 ? divider : null}
                </>
              );
            }
            return (
              <Grid key={item.key} container style={{ marginTop: 5 }}>
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: 600, fontSize: '1.15rem' }}>
                    {item.title}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: '1.15rem' }}>
                    {row[item.key]}
                  </Typography>
                </Grid>
                {item.key === 'supplierName' ? (
                  <Grid item xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                ) : null}
              </Grid>
            );
          })}
          {!isLoading &&
            <>
              <Grid item xs={12} container >
                {scanArray.length && scanTotalArray.length > scanPageCount ? <Grid item container xs={12} style={{ justifyContent: 'flex-end' }}>
                  <Pagination
                    shape={'rounded'}
                    variant={'text'}
                    size="small"
                    count={Math.ceil(Object.keys(scanList).length / scanPageCount)}
                    color="primary"
                    page={scanPage}
                    onChange={(_, page) => setScanPage(page)}
                    showFirstButton={true}
                    showLastButton={true}
                  />
                </Grid> : null}
                <Grid item xs={12} container style={{ padding: 0, maxHeight: '275px', overflowY: 'scroll', marginTop: 15 }}>
                  {scanArray.length ? <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date (by)</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>From (to)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {scanArray.map((scanItem, scanIndex) => {
                        let before: any = null;
                        let after: any = scanItem.total;
                        const itemDate = dayjs(scanItem.date);
                        const dataB4 = logList?.filter((l) => dayjs(l.createdAt).valueOf() < itemDate.valueOf());
                        const dataFr = logList?.filter((l) => dayjs(l.createdAt).valueOf() > itemDate.valueOf()).sort((a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf()).reverse();
                        const lowerDate = dataB4?.[0] ? dayjs(dataB4?.[0]) : itemDate.clone().startOf('month').subtract(1, 'month');
                        const prevItems = scanTotalArray.filter((sc) => dayjs(sc.date).valueOf() >= (lowerDate).valueOf() && dayjs(sc.date).valueOf() < itemDate.valueOf());
                        const prevTotal = prevItems.map((pi) => pi.quantity).reduce((prev, curr) => {
                          return prev - curr;
                        }, dataB4?.[0]?.quantity || dataFr?.[0]?.quantity - (scanItem.quantity) || Math.abs(scanItem.quantity));
                        console.log(itemDate.toString(), (lowerDate).valueOf(), scanTotalArray[0].date);
                        if (noTypes.includes(scanItem.scanType)) {
                          after = null;
                        } else if (noBeforeTypes.includes(scanItem.scanType)) {
                          after = scanItem.total;
                        } else if (qtyMinus.includes(scanItem.scanType)) {
                          if (dataFr?.[0]) {
                            before = prevTotal - (scanItem.quantity);
                            after = prevTotal;
                          } else {
                            before = prevTotal;
                            after = prevTotal + (scanItem.quantity);
                          }
                          // } else if (!item.total) {
                          //   before = item.total || 0;
                          //   after = item.quantity;
                        } else {
                          /* if (dataB4?.[0]) {
                            before = prevTotal;
                            after = prevTotal + (scanItem.quantity);
                          } else if (dataFr?.[0]) {
                            before = prevTotal - (scanItem.quantity);
                            after = prevTotal;
                          } else {
                            before = prevTotal;
                            after = Math.abs(scanItem.quantity);
                          } */
                          if (dataFr?.[0]) {
                            before = prevTotal + (scanItem.quantity);
                            after = prevTotal;
                          } else {
                            before = prevTotal;
                            after = prevTotal + (scanItem.quantity);
                          }
                        }
                        const beforeAfter = !before && !after ? null : `${before} (${after})`
                        return (
                          <TableRow key={`scanlist-${scanPage}-${scanIndex}`} style={{ paddingBottom: '4px', paddingTop: '4px' }}>
                            <TableCell style={{ paddingBottom: '4px', paddingTop: '4px' }}>{itemDate.format('MMM DD, YY')}<br />({scanItem.username})</TableCell>
                            <TableCell style={{ paddingBottom: '4px', paddingTop: '4px' }}>{scanItem.scanType}</TableCell>
                            <TableCell style={{ paddingBottom: '4px', paddingTop: '4px' }}>{scanItem.quantity}</TableCell>
                            <TableCell style={{ paddingBottom: '4px', paddingTop: '4px' }}>{beforeAfter}</TableCell>
                          </TableRow>
                        );
                      })}
                      {/*  {scanListKeys.map((key, scidx) => {
                        const item = scanList[key];
                        let before: any = null;
                        let after: any = item.total;
                        const date = dayjs(item.date);
                        const dataB4 = logged?.filter((l) => dayjs(l.createdAt) < date);
                        const dataFr = logged?.filter((l) => dayjs(l.createdAt) > date).sort((a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf()).reverse();

                        if (noTypes.includes(item.scanType)) {
                          after = null;
                        } else if (noBeforeTypes.includes(item.scanType)) {
                          after = item.total;
                        } else if (qtyMinus.includes(item.scanType)) {
                          if (dataB4?.[0]) {
                            after = dataB4?.[0]?.quantity - Math.abs(item.quantity);
                            before = dataB4?.[0]?.quantity;
                          } else {
                            before = item.quantity;
                            after = row.quantity - item.quantity;
                          }
                          // } else if (!item.total) {
                          //   before = item.total || 0;
                          //   after = item.quantity;
                        } else {
                          if (dataB4?.[0]) {
                            after = dataB4?.[0]?.quantity - item.quantity;
                          }
                          before = dataB4?.[0]?.quantity;
                        }
                        const beforeAfter = !before && !after ? null : `${before} (${after})`
                        return (
                          <TableRow key={`scanlist-${scidx}`} style={{ paddingBottom: '4px', paddingTop: '4px' }}>
                            <TableCell style={{ paddingBottom: '4px', paddingTop: '4px' }}>{date.format('MMM DD, YY')}<br />({item.username})</TableCell>
                            <TableCell style={{ paddingBottom: '4px', paddingTop: '4px' }}>{item.scanType}</TableCell>
                            <TableCell style={{ paddingBottom: '4px', paddingTop: '4px' }}>{item.quantity}</TableCell>
                            <TableCell style={{ paddingBottom: '4px', paddingTop: '4px' }}>{beforeAfter}</TableCell>
                          </TableRow>
                        );
                      })} */}
                    </TableBody>
                  </Table> : <p>No History for Part</p>}
                </Grid>
              </Grid>
              <Grid item xs={12} container>
                {fitListKeys.length && Object.keys(fitmentList).length > fitPageCount ? <Grid item container xs={12} style={{ justifyContent: 'flex-end' }}>
                  <Pagination
                    shape={'rounded'}
                    variant={'text'}
                    size="small"
                    count={Math.ceil(Object.keys(fitmentList).length / fitPageCount)}
                    color="primary"
                    page={fitPage}
                    onChange={(_, page) => setFitPage(page)}
                    showFirstButton={true}
                    showLastButton={true}
                  />
                </Grid> : null}
                {fitListKeys.length ? <Grid item xs={12} container style={{ padding: 0, maxHeight: '275px', overflowY: 'scroll', marginTop: 15 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Year</TableCell>
                        <TableCell>Make</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>Type</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(fitmentList).sort().map((make) => {
                        return (Object.keys(fitmentList[make]).sort().map((model) => {
                          return (
                            <TableRow key={`fitment-${row.id}-${make}-${model}`}>
                              <TableCell>{fitmentList[make][model]}</TableCell>
                              <TableCell>{make}</TableCell>
                              <TableCell>{model}</TableCell>
                              <TableCell title='This is currently hard coded pending data validation'>ATV</TableCell>
                            </TableRow>
                          )
                        }));
                      })}
                    </TableBody>
                  </Table>
                </Grid> : <p>No Fitment for Part</p>}
              </Grid>
            </>
          }
        </Grid>
      </SwipeableDrawer>
    </>
  );
}
