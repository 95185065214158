import { Grid } from '@material-ui/core';
import type {
  Column,
  ChildColumn,
} from '@src/Components/common/containers/SearchView/types';


export const columns: Column[] = [
  // {
  //   title: 'ID',
  //   keyName: 'id',
  //   sortBy: true,
  //   align: 'center',
  // },
  {
    title: 'Model',
    keyName: 'model',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'Make',
    keyName: 'make',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'Year(s)',
    keyName: 'modelYears',
    sortBy: true,
    align: 'left',
    customComponent: function (row) {
      return (<>
        {row?.modelYears?.map((year, index, arr) => {
          return (index + 1) % 10 === 0 ? (
            <>
              {year}
              <br />
            </>
          ) : index === 0 && arr.length === 1 ? (
            `${year}`
          ) : index === arr.length - 1 ? (
            `${year}`
          ) : (
            `${year}, `
          );
        })}
      </>);
    },
  },
  // {
  //   title: 'Edit',
  //   keyName: 'actions',
  //   sortBy: true,
  //   align: 'center',
  // },
  {
    keyName: 'items',
    type: 'parent',
    title: '',
  },
];

export const childColumns: ChildColumn[] = [
  // {
  //   title: 'ID',
  //   keyName: 'id',
  //   sortBy: true,
  //   align: 'center',
  //   parentKeyName: 'items',
  // },
  {
    title: 'Product',
    keyName: 'name',
    sortBy: true,
    align: 'left',
    parentKeyName: 'items',
    customComponent: function (row) {
      return (<>
        <Grid container item xs={12}>
          {row.name}{row.variant ? ` (${row.variant})` : ''}
        </Grid>
      </>);
    }
  },
  {
    title: 'Sku',
    keyName: 'sku',
    sortBy: true,
    align: 'center',
    parentKeyName: 'items',
  },
  // {
  //   title: 'Edit',
  //   keyName: 'actions',
  //   sortBy: true,
  //   align: 'center',
  //   parentKeyName: 'items',
  // },
];
