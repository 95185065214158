import BaseApi from '../BaseApi';

import { BrandForEdit } from 'types/brandTypes';
import type { Search } from 'types/searchTypes';

interface SearchResponse {
  rows: BrandForEdit[];
  count: number;
}

class ShipStationApi extends BaseApi {
  constructor() {
    super();
  }

  // put(id: number, payload: BrandForEdit) {
  //   return this.putBase<BrandForEdit>(
  //     `/api/v1/brands/${id}`,
  //     payload,
  //   );
  // }

  // post(payload: BrandForEdit) {
  //   return this.postBase<BrandForEdit>(
  //     `/api/v1/brands`,
  //     payload,
  //   );
  // }

  // getById(id: number) {
  //   return this.getBase<BrandForEdit>(`/api/v1/brands/${id}`);
  // }

  // search(payload: Partial<Search>) {
  //   return this.getBase<SearchResponse>(`/api/v1/brands/search`, payload);
  // }

  getCarriers() {
    return this.getBase<any>(`/api/v1/print-label/carriers-data`);
  }
  getAllRates(form: any, serviceOptions?: any) {
    return this.postBase<any>(`/api/v1/print-label/get-all-rates`, { form, serviceOptions });
  }
  getRates(form: any) {
    return this.postBase<any>(`/api/v1/print-label/get-rates`, { form });
  }
  getIntegration(name: string = 'ShipStation') {
    return this.getBase<any>(`/api/v1/print-label/integration/${name}`);
  }
  getLabel(labelId: number | string) {
    return this.getBase<any>(`/api/v1/print-label/label/${labelId}`)
  }
  loadCarrierRates(form: any) {
    return this.postBase<any>(`/api/v1/print-label/carrierServiceCodes`, { form })
  }
  cancelShipment(order: any) {
    return this.putBase<any>(`/api/v1/print-label/cancel`, { order });
  }
  printLabel(body: any) {
    return this.postBase<any>(`/api/v1/print-label`, body);
  }
  updateShipRates(body: any) {
    return this.postBase<any>(`/api/v1/print-label/update-ship-rates`, body);
  }
}

export default ShipStationApi;
