import { useState, useEffect } from 'react';
import {
  Modal,
  Table,
  TableRow,
  TableHead,
  TableCell,
  Grid,
  Button,
  Typography,
  TableBody,
} from '@material-ui/core';
import Api from '@oneAppCore/services/Api';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';
import { Listings } from '@src/Components/Products/ProductPage/VariationsTable/TableRows/listings/types';
import useVariationData from '@src/hooks/swr/Inventory/useVariationData';
import useVarDataBulk from '@src/Components/Inventory/Management/hooks/useVarDataBulk';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  },
}));

interface ListingModalProps {
  isOpen: boolean;
  onClose: () => void;
  listingData?: Listings[];
  variationId?: number;
  manufacturerNo?: any;
  id?: string;
  selectedRows?: any[];
  channels?: any;
  productData?: any;
  setLoading?: (loading: boolean) => void;
}

interface RouteParams {
  id: string;
}

function ListingModal({
  isOpen,
  onClose,
  listingData,
  variationId,
  manufacturerNo,
  selectedRows,
  channels,
  setLoading,
}: ListingModalProps) {
  const [open, setOpen] = useState(false);
  const [selectedRowsData, setSelectedRows] = useState([]);
  const [variationData, setVariationData] = useState([]);
  const [loading, isLoading] = useState(true);
  const [productData, setProductData] = useState([]);
  const history = useHistory();
  const classes = useStyles();
  const close = () => {
    setOpen(false);
    setProductData([]);
  };
  const { id: paramId } = useParams<RouteParams>();
  const productIds = selectedRowsData.map((item) => item.id);

  const { data: variations, mutate: mutateVariations } = useVariationData({
    id: Number(paramId),
  });

  useEffect(() => {
    if (selectedRows?.length > 0) {
      setProductData(selectedRows);
    }
  }, [selectedRows]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await useVarDataBulk(productData.map((row) => row.id));
        setVariationData(data);
      } catch (error) {
        console.error('Error fetching variation data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [productData, setLoading]);

  useEffect(() => {
    mutateVariations();
  }, [isOpen]);

  const variationsBulk = variationData.flat();

  const listings = variations?.map((variation, i) => {
    return variation.listings;
  });

  const bulkListings = variationsBulk?.map((variation, i) => {
    return variation.listings;
  });

  return (
    <Grid container xs={12} style={{ justifyContent: 'center' }}>
      <Modal className={classes.modal} open={isOpen}>
        <Grid
          container
          style={{
            width: 600,
            backgroundColor: 'white',
            borderRadius: 10,
            justifyContent: 'center',
            padding: 15,
          }}
        >
          <Grid item style={{ marginBottom: 15 }}>
            <Typography variant="h6">Created Listings</Typography>
          </Grid>

          <Grid
            item
            container
            xs={12}
            style={{
              justifyContent: 'center',
              marginBottom: 10,
              maxHeight: '200px',
              overflowY: 'scroll',
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: '#f3f2f7',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <TableCell align="center">Image</TableCell>
                  <TableCell align="center">Item Name</TableCell>
                  <TableCell align="center">Channel</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listings !== undefined
                  ? listings?.map((innerListings, index) =>
                      innerListings?.map((listing, i) => {
                        const variation = variations[index];
                        return (
                          <TableRow key={`${listing.id}-${i}`}>
                            <TableCell align="center">
                              <img
                                height={40}
                                width={40}
                                src={variation.imageUrl}
                              />
                            </TableCell>
                            <TableCell>
                              {variation.name} {variation.variation}
                            </TableCell>
                            <TableCell>
                              <img height={40} width={40} src={listing.icon} />
                            </TableCell>
                            <TableCell>{listing.status}</TableCell>
                            <TableCell>
                              {formatCentsToDollarsIntl(listing.price)}
                            </TableCell>
                          </TableRow>
                        );
                      }),
                    )
                  : bulkListings?.map((row, index) =>
                      row?.map((item, i) => {
                        const variation = variationsBulk[index];
                        return (
                          <TableRow key={`${item.id}-${i}`}>
                            <TableCell align="center">
                              <img
                                height={40}
                                width={40}
                                src={variation.imageUrl}
                              />
                            </TableCell>
                            <TableCell>
                              {variation.name} {variation.variation}
                            </TableCell>
                            <TableCell>
                              <img height={40} width={40} src={item.icon} />
                            </TableCell>
                            <TableCell>{item.status}</TableCell>
                            <TableCell>
                              {formatCentsToDollarsIntl(item.price)}
                            </TableCell>
                          </TableRow>
                        );
                      }),
                    )}
              </TableBody>
            </Table>
          </Grid>

          <Grid item container style={{ justifyContent: 'flex-end' }}>
            <Grid item>
              <Button
                onClick={() => {
                  onClose();
                  setProductData([]);
                }}
                style={{ color: 'red' }}
              >
                Close
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => history.push('/listings/draft')}
                color="primary"
              >
                View In Drafts
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
}

export default ListingModal;
