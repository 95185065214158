import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Grid,
  IconButton,
  Tooltip,
  Modal,
  Button,
  TextField,
  Link,
} from '@material-ui/core';
import FontAwesome from '@src/Components/common/FontAwesome';
import type { Props } from './types';
import { useSnackbar } from 'notistack';
import Api from '@oneAppCore/services/Api';
import { APPROVED, PENDING, REJECTED } from '@oneAppCore/constants/listings';
import useSearch from '@oneAppCore/one-app/src/Components/common/containers/SearchView/hooks/useSearch';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  },
}));

const options = {
  approve: {
    successMessage: 'Approved the listing',
    errorMessage: 'Error approving listing',
    title: 'Approve',
    modalContent:
      'This will submit selected listings directly to the sales channel.',
  },
  needChange: {
    successMessage: 'Rejected the listing',
    errorMessage: 'Error changing status of listing',
    title: 'Reject',
    modalContent: 'This will change the status of the listing to Rejected.',
  },
};

function ApproveButton({ row, modalType }: Props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const modalOption =
    modalType == REJECTED ? options.needChange : options.approve;
  const [message, setMessage] = useState('');
  const { mutate } = useSearch();
  const [openFeedback, setOpenFeedback] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setMessage('');
    setOpen(false);
  };

  const ApproveListing = async () => {
    const data = {
      price: Math.round(row.price * 100),
      maxPrice: Math.round(row.maxPrice * 100),
      minPrice: Math.round(row.minPrice * 100),
      status: modalType == REJECTED ? REJECTED : PENDING,
      message,
    };
    try {
      const updateData = await Api.put(
        `/api/v1/store-listings/${row.id}`,
        data,
      );
      if (updateData) {
        enqueueSnackbar(modalOption.successMessage, {
          variant: 'success',
        });
        mutate();
      }
    } catch (error) {
      enqueueSnackbar(modalOption.errorMessage, {
        variant: 'error',
      });
    } finally {
      {
        setMessage('');
        handleClose();
        setOpenFeedback(true);
      }
    }
  };

  return (
    <>
      <Grid item container justify="center">
        <Tooltip title={modalOption.title}>
          <IconButton
            disabled={!row.price || !row.minPrice || !row.maxPrice}
            style={{
              fontSize: '14px',
              color:
                !row.price || !row.minPrice || !row.maxPrice
                  ? 'grey'
                  : modalType == REJECTED
                  ? 'red'
                  : '#10960E',
              paddingLeft: '15%',
              paddingRight: '15%',
            }}
          >
            <FontAwesome
              name={modalType == REJECTED ? 'ban' : 'check'}
              type="fa"
              form="fa"
              onClick={handleOpen}
            />
          </IconButton>
        </Tooltip>
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            style={{
              width: 500,
              backgroundColor: 'white',
              borderRadius: 10,
              justifyContent: 'center',
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{
                justifyContent: 'center',
                width: '100%',
                borderRadius: '10px 10px 0px 0px',
              }}
            >
              <Grid
                item
                container
                xs={12}
                style={{
                  justifyContent: 'flex-end',
                  marginTop: 10,
                  marginRight: 10,
                  marginBottom: '-40px',
                }}
              >
                <Button onClick={() => handleClose()}>
                  <FontAwesome type="fa" form="fa" name="times" />
                </Button>
              </Grid>
              <h1>Are You Sure?</h1>
            </Grid>
            <Grid item container xs={10} style={{ justifyContent: 'center' }}>
              <p
                style={{
                  fontSize: '20px',
                  textAlign: 'center',
                }}
              >
                {modalOption.modalContent}
              </p>
            </Grid>
            {modalType == REJECTED && (
              <Grid
                item
                container
                xs={12}
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                  justifyContent: 'center',
                }}
              >
                <TextField
                  value={message}
                  placeholder={'Message (optional)'}
                  variant="outlined"
                  onChange={(e) => setMessage(e.target.value)}
                  style={{ width: '80%' }}
                />
              </Grid>
            )}
            <Grid
              item
              container
              xs={12}
              style={{ padding: 20, justifyContent: 'center' }}
            >
              <Grid
                item
                container
                xs={6}
                style={{ justifyContent: 'flex-start' }}
              >
                <Link
                  // variant="contained"
                  component="button"
                  variant="body2"
                  color="secondary"
                  onClick={() => {
                    history.push(`/products/view/${row.storeProductId}`);
                  }}
                >
                  Review Product
                </Link>
              </Grid>
              <Grid
                item
                container
                xs={6}
                style={{ justifyContent: 'flex-end' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => ApproveListing()}
                >
                  {modalOption.title}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        <Modal
          className={classes.modal}
          open={openFeedback}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            style={{
              width: 500,
              backgroundColor: 'white',
              borderRadius: 10,
              justifyContent: 'center',
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{
                justifyContent: 'center',
                width: '100%',
                borderRadius: '10px 10px 0px 0px',
              }}
            >
              <h1>Listing has been submitted!</h1>
            </Grid>
            <p style={{ fontSize: '20px', textAlign: 'center' }}>
              Please check back in 30 minutes!
            </p>
            <Grid
              item
              container
              xs={12}
              style={{ justifyContent: 'flex-end', padding: 15 }}
            >
              <Button
                onClick={() => setOpenFeedback(false)}
                style={{ color: 'crimson' }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </>
  );
}

export default ApproveButton;
