import { SearchOptionsAtom } from '@oneApp/Components/common/containers/SearchView/hooks/useSearchOptions/types';
import BaseApi from '../BaseApi';

import type { ProductPayload, Product, Variation } from './types';

interface SearchResponse {
  rows: any[];
  count: number;
}

class ProductApi extends BaseApi {
  constructor() {
    super();
  }

  getNames = async (params: Partial<SearchOptionsAtom>) => {
    return this.getBase<SearchResponse>(`api/v1/products/nameSearch`, params);
  };

  put(id: number, payload: Partial<ProductPayload>) {
    return this.putBase<Product>(`/api/v1/products/${id}`, payload);
  }

  post(payload: ProductPayload) {
    return this.postBase<Product>(`/api/v1/products`, payload);
  }

  createGroup(payload: ProductPayload) {
    return this.postBase<Product>(`api/v1/products/createGroup`, payload);
  }

  getById(id: number) {
    return this.getBase<Product>(`/api/v1/products/${id}`);
  }

  getProductVariations(id: number, params) {
    return this.getBase<Variation[]>(
      `/api/v1/products/${id}/variations`,
      params,
    );
  }

  updateImage(payload, id) {
    console.log(payload, 'payload');
    return this.putBase(`/api/v1/products/updateImage/${id}`, payload);
  }

  createImage(payload, id) {
    return this.postBase(`/api/v1/products/createImage/${id}`, payload);
  }
  search = async (params) => {
    return this.getBase<SearchResponse>(`/api/v1/products/search`, params);
  };
  removeOneProduct = async (id) => {
    return this.deleteBase(`/api/v1/products/${id}`);
  };
  getEnhancedProductDescription = async (payload) => {
    return this.postBase(
      `/api/v1/products/getEnhancedProductDescription`,
      payload,
    );
  };
}

export default ProductApi;
