import BaseApi from './BaseApi';

import type { Search } from 'types/searchTypes';
import type { Variation, UpcToVariation } from 'types/productTypes';

type SearchResponse = {
  data: Variation[];
  count: number;
};

class VariationsApi extends BaseApi {
  constructor() {
    super();
  }

  post(payload: Variation) {
    return this.postBase<Variation>(`/api/v1/variations`, payload);
  }

  put(id: number, payload: Variation) {
    console.log(payload, 'payload');
    return this.putBase<Variation>(`/api/v1/variations/${id}`, payload);
  }

  search = async (params: Partial<Search>) => {
    return this.getBase<SearchResponse>(
      `/api/v1/variations/api-search`,
      params,
    );
  };
  postUpc = async (payload: UpcToVariation) => {
    return this.postBase<string>(`/api/v1/upc-to-variation`, payload);
  };

  updateImage = async (payload, imageId) => {
    return this.putBase(`/api/v1/variations/updateImage/${imageId}`, payload);
  };

  createPack = async (payload) => {
    return this.postBase('/api/v1/item-groups', payload);
  };
}

export default new VariationsApi();
