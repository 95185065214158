import Api from '@oneAppCore/services/Api';
import useSWR from 'swr';

interface InventoryScanHistory {
  scanned?: any[];
  purchased?: any[];
  fitted?: any[];
  ordered?: any[];
  logged?: any[];
}

export default function useInventoryHistory(itemId: number) {
  return useSWR<InventoryScanHistory>(
    `/api/v1/inventory/scanhist/${itemId}`,
    Api.get,
    {
      initialData: {
        scanned: [],
        purchased: [],
        fitted: [],
        ordered: [],
      },
      revalidateOnMount: true,
      revalidateOnFocus: true,
    },
  );
}
