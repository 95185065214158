import Api from '@oneAppCore/services/Api';
import useSWR from 'swr';

type Params = {
  id: number;
  searchFilter?: string;
  filters?: any;
  sku?: string;
};

export default function useVariationData({
  id,
  searchFilter,
  filters,
  sku,
}: Params) {
  return useSWR(
    id
      ? `/api/v1/products/${id}/variations?searchFilter=${searchFilter}&sku=${sku}`
      : null,
    Api.get,
  );
}
