import { useHistory } from 'react-router-dom';
import { useState } from 'react';

import queryUtils from '@src/utils/queries';

import FontAwesome from '@src/Components/common/FontAwesome';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';
import { Tooltip, IconButton } from '@material-ui/core';
import ViewInventoryDrawer from '../ViewInventoryDrawer/ViewInventoryDrawer';

import type { Props } from './types';

function EditButton({ id, row }: Props) {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const viewManufacturerSupplier = () => {
    const query = queryUtils.add('id', id);
    history.replace({
      search: query,
    });
  };

  const close = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={`View Inventory Item`}>
        <span>
          <IconButton onClick={() => setOpen(true)}>
            <VisibilityTwoToneIcon color="primary" fontSize="default" />
          </IconButton>
        </span>
      </Tooltip>
      {open ? <ViewInventoryDrawer
        isDrawerOpen={open}
        setDrawerOpen={close}
        row={row}
      /> : null}
    </>
  );
}

export default EditButton;
