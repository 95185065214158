import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import PrintLabelModal from '../PrintLabelModal';
import Api from '@oneAppCore/services/Api';
import FontAwesome from '@src/Components/common/FontAwesome';
import shipStationApi from '@oneAppCore/services/ShipStationApi';
import type { Props } from './types';
import printJS from 'print-js';
import {
  Tooltip,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  ButtonGroup,
} from '@material-ui/core';
import { PrintLabelForm } from '../PrintLabelModal/types';
import useSearch from '../../common/containers/SearchView/hooks/useSearch';
import { addressStringFormatter } from '@src/utils/addressStringLimiter';
import { TRACKED, UNASSIGNED } from '@oneAppCore/constants/orders';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  FEDEX,
  FEDEX_CODE,
  UPS,
  UPS_CODE,
  USPS,
  USPS_CODE,
  US_TERRITORIES,
} from '@oneAppCore/constants/shipping';
import {
  convertDollarsToCents,
  formatCentsToDollars,
} from '@src/utils/currency';

const generateUrl = (base64Pdf: string) => {
  const byteCharacters = atob(base64Pdf);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], { type: 'application/pdf' });
  return URL.createObjectURL(blob);
};

const useStyles = makeStyles((theme: Theme) => ({
  printButton: {
    backgroundColor: 'rgba(248,161,79)',
    '&:hover': {
      backgroundColor: 'rgba(238,151,69)',
    },
  },
}));

function PrintLabel({
  id,
  row,
  orderType = 'order',
  carrierData,
  me,
  label: labelProp,
  mutate,
  shipRates,
}: Props) {
  const classes = useStyles();
  const [clicked, setClicked] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState<PrintLabelForm>({
    carrierCode: shipRates?.[id]?.carrierCode ? shipRates?.[id]?.carrierCode : 'stamps_com',
    serviceCode: shipRates?.[id]?.serviceCode ? shipRates?.[id]?.serviceCode : 'usps_priority_mail',
    packageCode: 'package',
    carrierOptions: shipRates?.[id]?.carrierOptions ? shipRates?.[id]?.carrierOptions : [],
    serviceOptions: shipRates?.[id]?.serviceOptions ? shipRates?.[id]?.serviceOptions : [],
    packageOptions: [],
    selectedServiceName: shipRates?.[id]?.selectedServiceName ? shipRates?.[id]?.selectedServiceName : 'USPS Priority Mail - Package',
    shipFrom: null,
    shipTo: null,
    weight: {
      value: null,
      units: 'ounces',
    },
    dimensions: {
      units: 'inches',
      length: shipRates?.[id]?.length ? shipRates?.[id]?.length : 1,
      width: shipRates?.[id]?.width ? shipRates?.[id]?.width : 4,
      height: shipRates?.[id]?.height ? shipRates?.[id]?.height : 8,
    },
    profit: shipRates?.[id]?.profit ? shipRates?.[id]?.profit : 0,
    allRates: shipRates?.[id]?.allRates ? shipRates?.[id]?.allRates : {},
  });
  const [carrierServiceCodes, setCarrierServiceCodes] = useState([]);
  const [orderItems, setOrderItems] = useState([]);

  const { mutate: ordersMutate } = useSearch();

  // useEffect(() => {
  //   console.log('label', labelProp);
  // }, [labelProp])

  const { enqueueSnackbar } = useSnackbar();
  const [hover, setHover] = useState<boolean>(false);

  useEffect(() => {
    const tempForm = {
      ...form,
      carrierCode: shipRates?.[id]?.carrierCode ? shipRates?.[id]?.carrierCode : 'stamps_com',
      serviceCode: shipRates?.[id]?.serviceCode ? shipRates?.[id]?.serviceCode : 'usps_priority_mail',
      packageCode: 'package',
      carrierOptions: shipRates?.[id]?.carrierOptions ? shipRates?.[id]?.carrierOptions : [],
      serviceOptions: shipRates?.[id]?.serviceOptions ? shipRates?.[id]?.serviceOptions : [],
      packageOptions: [],
      selectedServiceName: shipRates?.[id]?.selectedServiceName,
      shipFrom: null,
      shipTo: null,
      weight: {
        value: null,
        units: 'ounces',
      },
      dimensions: {
        units: 'inches',
        length: shipRates?.[id]?.length ? shipRates?.[id]?.length : 1,
        width: shipRates?.[id]?.width ? shipRates?.[id]?.width : 4,
        height: shipRates?.[id]?.height ? shipRates?.[id]?.height : 8,
      },
      profit: shipRates?.[id]?.profit ? shipRates?.[id]?.profit : 0,
      allRates: shipRates?.[id]?.allRates ? shipRates?.[id]?.allRates : {},
    };
    setForm(tempForm);
  }, [shipRates[id]]);

  const extractOuncesFromPounds = (weight: number) => {
    let afterDecimalWeight = (weight - Math.floor(weight)).toFixed(2);
    return parseFloat(afterDecimalWeight) * 16;
  };

  const printLabel = async (printForm, label = null) => {
    try {
      setClicked(true);
      const isTest = false;
      if (!label) {
        const response = await shipStationApi.printLabel({
          order: row,
          shipStationForm: printForm,
          isTest,
        });
        const { labelData, orderUpdate } = response;

        label = labelData;
        if (!isTest) {
          if (orderUpdate.orderStatus) await Api.put(`/api/v1/orders/${row.id}`, orderUpdate);

          enqueueSnackbar(`Label Successfully Created`, {
            variant: 'success',
          });
        }
      } else {
        if (row.orderStatus && row.orderStatus === UNASSIGNED) {
          if (!isTest) {
            let supplierTrackingCarrier = 'USPS';
            if (printForm.carrierCode == USPS_CODE) {
              supplierTrackingCarrier = USPS;
            } else if (printForm.carrierCode == FEDEX_CODE) {
              supplierTrackingCarrier = FEDEX;
            } else if (printForm.carrierCode == UPS_CODE) {
              supplierTrackingCarrier = UPS;
            }

            const orderUpdate = {
              orderStatus: TRACKED,
              supplierShippingCost: row.labelPrice ? row.labelPrice : 0,
              orderItems: row.orderItems.map((oItem) => ({
                id: oItem.id,
                supplierTrackingCarrier,
                supplierOrderNo: 'Internal',
                // ! after view uses shipping cost on orders remove this from orderItems
                supplierShippingCost: row.labelPrice
                  ? row.labelPrice
                  : 0,
                supplierTrackingNo: `${row.labelTracking}`,
              })),
            };
            await Api.put(`/api/v1/orders/${row.id}`, orderUpdate);

            enqueueSnackbar(`Order successfully Placed.`, {
              variant: 'success',
            });
          }
        }
      }

      const url = generateUrl(label);

      // as we are getting the reprint rows from BackupOutlined.
      // mutate();
      printJS(url);
      setClicked(false);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(`${error || error.message}`, {
        variant: 'error',
      });
      setClicked(false);
    } finally {
      mutate();
    }
  };

  const handleOpen = async (quickPrint = false) => {
    try {
      setClicked(true);
      let label;
      if (!row.label) {
        label = await shipStationApi.getLabel(row.id);
      } else {
        label = row.label;
      }
      if (!label) {
        // Get shipFrom Data
        const response = await shipStationApi.getIntegration();

        // Get product weights
        let weight = 0;
        let totalWeightInOunces = 0;
        let totalWeightInPounds = 0;
        const orderItems = await Promise.all(
          row.orderItems.map(async (item) => {
            if (!item?.variationId) {
              throw `Order item with ID of ${item.id} is missing a variation Id. Cannot pull weight. This probably means there is a data inconsistency.`;
            }

            const { variation } = await Api.get(
              `/api/v1/variations/${item.variationId}`,
            );
            const orderItem = {
              id: item.id,
              imageUrl: item.variationImages[0]?.imageUrl,
              variationId: variation.id,
              sku: variation.sku,
              name: item?.channelData?.title,
              weight: variation?.weight
                ? (variation.weight * 16).toFixed(2)
                : 0, //to save weight in pounds
              weightInPounds:
                variation?.weight > 1 ? Math.floor(variation.weight) : 0,
              weightInOunces: variation?.weight
                ? extractOuncesFromPounds(variation.weight)
                : 0,
              quantity: item.quantity,
              location: item.location,
              manufacturerNo: item.manufacturerNo,
              supplierId: item.supplierId,
            };

            // On the assumption that weight is stored in pounds
            weight += variation.weight
              ? parseFloat(variation.weight) * item.quantity * 16
              : 0;
            totalWeightInOunces += variation.weight
              ? Math.ceil(extractOuncesFromPounds(variation.weight)) *
                item.quantity
              : 0;
            totalWeightInPounds +=
              variation.weight && variation.weight > 1
                ? Math.floor(variation.weight) * item.quantity
                : 0;
            return orderItem;
          }),
        );
        setOrderItems(orderItems);

        if (
          US_TERRITORIES.includes(row.country.replaceAll('.', '').toUpperCase())
        ) {
          row.state = row.country.replaceAll('.', '').toUpperCase();
          row.country = 'US';
        }

        const addressArray = addressStringFormatter(
          row.addressLine1,
          row.addressLine2,
          row.addressLine3,
        );
        // Set a new form to overcome state sync issues
        const newForm = {
          ...form,
          carrierCode:
            form.carrierCode ? form.carrierCode :
              Math.round(weight) >= 128 ? 'ups_walleted' : 'stamps_com',
          serviceCode:
            form.serviceCode ? form.serviceCode :
              Math.round(weight) >= 128
                ? 'ups_ground'
                : Math.round(weight) >= 16
                  ? 'usps_priority_mail'
                  : 'usps_first_class_mail',
          selectedServiceName:
            form.selectedServiceName ? form.selectedServiceName :
              Math.round(weight) >= 128
                ? 'UPS® Ground'
                : Math.round(weight) >= 16
                  ? 'USPS Priority Mail - Package'
                  : 'USPS First Class Mail - Package',
          shipTo: {
            name: row.shippingName,
            company: row.orderNumber.includes('# ')
              ? row.orderNumber.split('# ')[1]
              : row.orderNumber,
            street1: addressArray[0],
            street2: addressArray[1],
            street3: addressArray[2],
            city: row.city,
            state: row.state,
            postalCode: row.zipCode,
            country: row.country === 'USA' ? 'US' : row.country, // Fix shipstations 2 letter requirement
            phone: '',
            residential: true,
          },
          shipFrom: response.shipStationData.integrationData.shipFrom,
          weight: { value: Math.round(weight), units: 'ounces' },
          totalWeightInOunces: totalWeightInOunces,
          totalWeightInPounds: totalWeightInPounds,
          orderCosts: {
            subTotal: row.orderItems.reduce((accumulator, currentValue) => {
              return accumulator + currentValue?.supplierSubTotal;
            }, 0),
            itemPrice: row.orderItems.reduce((accumulator, currentValue) => {
              return accumulator + currentValue?.itemPrice;
            }, 0),
          },
          profit:
            form.profit ? form.profit :
            row.orderItems.reduce((accumulator, currentValue) => {
              return accumulator + currentValue?.itemPrice;
            }, 0) -
            row.orderItems.reduce((accumulator, currentValue) => {
              return accumulator + currentValue?.supplierSubTotal;
            }, 0),
        };
        setForm({
          ...newForm,
        });
        if (quickPrint) {
          await printLabel(newForm);
        } else {
          setOpen(true);
        }
      } else {
        const lab = typeof label === 'string' ? label : label.label;
        printLabel(form, lab);
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(`Error while trying to open print module: ${e}`, {
        variant: 'error',
      });
      setClicked(false);
    }
  };

  const loadCarrierRates = async (form) => {
    const csResponse = await shipStationApi.loadCarrierRates(form);
    setCarrierServiceCodes(csResponse);
  };

  const handleClose = (value) => {
    setClicked(false);
    setOpen(false);
  };

  const handleCancelOpen = () => {
    setCancelOpen(true);
  };

  const handleCancelClose = () => {
    setCancelOpen(false);
  };

  const cancelShipment = async () => {
    try {
      const response = await shipStationApi.cancelShipment(row);

      if (response.success) {
        const { orderUpdate } = response;

        await Api.put(`/api/v1/orders/${row.id}`, orderUpdate);

        enqueueSnackbar('Shipment successfully voided.', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Error while trying to void shipment.', {
          variant: 'error',
        });
      };
    } catch (error) {
      enqueueSnackbar(`Error while trying to void shipment. ${error}`, {
        variant: 'error',
      });
    } finally {
      ordersMutate();
      mutate();
      setCancelOpen(false);
    };
  };

  return (
    <>
      {/* Modals and Dialogues */}
      {open && (
        <PrintLabelModal
          open={open}
          printLabel={printLabel}
          openHandler={handleOpen}
          closeHandler={handleClose}
          defaultForm={form}
          carrierServiceCodes={carrierServiceCodes}
          loadCarrierRates={loadCarrierRates}
          row={row}
          originalOrderItems={orderItems}
          carrierData={carrierData}
          me={me}
        />
      )}
      {cancelOpen && (
        <Dialog open={cancelOpen} onClose={handleCancelClose}>
          <DialogTitle>{'Are you sure?'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure that you want to cancel this shipment? This will void
              the label in ShipStation and void any currently printed labels.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelClose}>No</Button>
            <Button onClick={cancelShipment} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Print / Cancel Icons */}
      <Grid xs={12} item container style={{ justifyContent: 'center' }}>
        {!clicked && (
          <Grid item xs={12} md={12} lg={8}>
            <Tooltip title={row.label ? 'Reprint' : 'Print'}>
              <span>
                <ButtonGroup color="primary" variant="contained" aria-label="Basic button group">
                  <Button
                    // variant={quickPrint ? "contained" : "outlined"}
                    // color="primary"
                    disabled={shipRates?.[id]?.weight < 0.51 || (!shipRates?.[id] && !row.label)}
                    onClick={() => handleOpen(true)}
                    size="small"
                    startIcon={
                      <FontAwesome
                        size="small"
                        name="print"
                        type="fas"
                        form="fa"
                      />
                    }
                    className={(row.label || labelProp) && classes.printButton}
                  >
                    {row.label || labelProp ? 'Reprint' : 'Print'}
                  </Button>
                  {!(row.label || labelProp) && <Button
                    onClick={() => handleOpen(false)}
                    size="small"
                  >
                    <FontAwesome
                      size="small"
                      name="edit"
                      type="fas"
                      form="fa"
                    />
                  </Button>}
                  {(row.label || labelProp) && <Button
                    onClick={handleCancelOpen}
                    size="small"
                    className={(row.label || labelProp) && classes.printButton}
                  >
                    <FontAwesome size='small' name="ban" type="fas" form="fa" />
                  </Button>}
                </ButtonGroup>
              </span>
            </Tooltip>
          </Grid>
        )}
        {clicked && (
          <Grid item>
            <Tooltip title={'loading'}>
              <span>
                <IconButton
                  style={{ fontSize: '14px' }}
                  className="MuiCircularProgress-svg"
                >
                  <FontAwesome spin name="spinner" type="fas" form="fa" />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        )}
        {/* {row.label && (
          <Grid item>
            <Tooltip title={'Cancel Shipment'}>
              <span>
                <IconButton
                  style={{ fontSize: '14px', color: '#EA5455' }}
                  onClick={handleCancelOpen}
                >
                  <FontAwesome name="ban" type="fas" form="fa" />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        )} */}
      </Grid>
    </>
  );
}

export default PrintLabel;
