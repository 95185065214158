import { TableCell, TableHead, TableRow, Checkbox } from '@material-ui/core';
import {
  makeStyles,
  Theme,
  withStyles,
  createStyles,
} from '@material-ui/core/styles';
import { useRecoilState } from 'recoil';
import { reposState as reposAtom } from '../atoms';
import useSearch from '../../hooks/useSearch';
import AscIcon from './DirectionIcons/AscIcon';
import DescIcon from './DirectionIcons/DescIcon';

import type { Props } from './types';
import { memo } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 650,
  },
  cellContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filter: {
    width: 20,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(0.5),
  },
  column: {
    border: 0,
  },

  headerColor: {
    backgroundColor: '#f3f2f7',
    border: 0,
    whiteSpace: 'nowrap',
  },
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#f3f2f7',
    },
    body: {
      fontSize: 10,
    },
  }),
)(TableCell);

function SearchHeader({
  columns,
  childColumns = [],
  select,
  selectAllRows,
  deselectAllRows,
  selectedRows,
  swrOptions = {
    revalidateOnFocus: true,
    focusThrottleInterval: 3500,
  },
  selectedFilterValues,
}: Props) {
  const classes = useStyles();

  // Setting all rows to the state when clicking on the select button
  const { data } = useSearch(swrOptions.revalidateOnFocus, false, swrOptions.focusThrottleInterval);
  const rows = data?.rows || [];

  const [selectedTableRows, setSelectedTableRows] = useRecoilState(reposAtom);

  return (
    <TableHead className={classes.headerColor}>
      <TableRow>
        {select && (
          <StyledTableCell
            align={'center'}
            key={'select-rows-header'}
            className={classes.column}
          >
            <Checkbox
              onChange={() => {
                if (selectedRows?.length === rows.length) {
                  deselectAllRows();
                } else {
                  selectAllRows(rows);
                }
              }}
              checked={selectedRows?.length === rows.length && rows.length > 0}
            />
          </StyledTableCell>
        )}
        {columns.filter((col)=> !col.hidden).filter((col) => col.type !== 'parent').map((column) => {
          if (column.sortBy) {
            return (
              <StyledTableCell
                align={column.align || 'center'}
                key={column.keyName}
                className={classes.column}
              >
                <div className={classes.cellContentWrapper}>
                  <div>{column.customTitle
                    ? column.customTitle(selectedFilterValues)
                    : column.title}</div>
                  <div className={classes.filter}>
                    <AscIcon keyName={column.keyName} />
                    <DescIcon keyName={column.keyName} />
                  </div>
                </div>
              </StyledTableCell>
            );
          }
          return (
            <StyledTableCell
              align={column.align || 'center'}
              key={column.keyName}
              className={classes.column}
              style={{
                maxWidth: column.keyName === 'name' ? '120px' : '100%',
              }}
            >
              {column.customTitle
                ? column.customTitle('')
                : column.title}
            </StyledTableCell>
          );
        })}
        {childColumns.length > 0 && (
          <StyledTableCell
            align={'center'}
            key={'select-rows-header-child'}
            className={classes.column}
          />
        )}
      </TableRow>
    </TableHead>
  );
}

export default memo(SearchHeader);
