import { columns, childColumns } from './constants';
import SearchView from '@src/Components/common/containers/SearchView';
import { Grid } from '@material-ui/core';
// import { useRecoilState, useRecoilValue } from 'recoil';
// import { reposState as reposAtom } from '../../common/containers/SearchView/SearchTable/atoms';
import { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useMeStores from '@src/hooks/swr/useMeStores';
import useSuppliers from '@src/hooks/swr/useSuppliers'
import { APPROVED, CREATED, PENDING, REJECTED } from '@oneAppCore/constants/listings';
import { capitalize } from '@oneAppCore/utils/formatters';
import { LAW_MAP } from '@oneAppCore/constants/laws';

const statusList = [
  { label: capitalize(CREATED), value: CREATED },
  { label: capitalize(APPROVED), value: APPROVED },
  { label: capitalize(PENDING), value: PENDING },
  { label: capitalize(REJECTED), value: REJECTED },
];
const typeList = Object.keys(LAW_MAP).map((key) => ({
  label: LAW_MAP[key],
  value: key,
}));

/*
  filters:
  old/new supplier
  old/new cost
  store
  created at
  

*/

const useStyles = makeStyles((theme: Theme) => ({
  tablePaper: {
    width: '425px',
    borderRadius: '10px',
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    background: theme.palette.background.paper,
    boxShadow: `0px 0px 2px ${theme.palette.black.darkest}`,
  },
  tooltip: {
    maxWidth: '430px',
    borderRadius: '10px',
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    background: 'transparent',
  },
  tableCell: {
    fontWeight: 'bold',
  },
}));
function ListingsSearch() {
  const classes = useStyles();
  const { data } = useMeStores();
  const { data: suppliers } = useSuppliers();
  const [stores, setStores] = useState([{ label: 'None', value: null }]);
  useEffect(() => {
    if (data) {
      setStores(() => [
        ...data.map((store) => ({
          label: store.name,
          value: store.id,
        })),
      ]);
    }
  }, [data]);
  // const tableRowsState = useRecoilValue(reposAtom);

  return (
    <Grid item container justifyContent="center" alignItems="center" spacing={1}>
      <SearchView
        url="/api/v1/laws/search"
        showChannelOptions
        supportsDownload
        select
        columns={columns(suppliers, {})}
        childColumns={childColumns(suppliers, {})}
        filters={[
          {
            type: 'selector',
            name: 'Status',
            keyName: 'status',
            properties: statusList,
          },
          {
            type: 'selector',
            name: 'Law',
            keyName: 'law',
            properties: typeList,
          },
          /*  {
            type: 'string',
            name: 'First Name',
            keyName: 'firstName',
          },
          {
            type: 'selector',
            name: 'Status',
            keyName: 'status',
            properties: [
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' },
            ],
          },
          {
            type: 'selector',
            name: 'Store',
            keyName: 'store',
            properties: stores,
          },
          {
            type: 'date range',
            name: 'Created At',
            keyName: 'createdAt',
          },
          {
            type: 'string',
            name: 'Sku',
            keyName: 'sku',
          },
          {
            type: 'string',
            name: 'MPN',
            keyName: 'mpn',
          },
          {
            type: 'string',
            name: 'Brand',
            keyName: 'brand',
          }, */
        ]}
      />
    </Grid>
  );
}

export default ListingsSearch;
