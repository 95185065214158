// order statuses
export const CANCELLED = 'CANCELLED';
export const COMPLETED = 'COMPLETED';
export const FAILED = 'FAILED';
export const ISSUE = 'ISSUE';
export const PENDING = 'PENDING';
export const PLACED = 'PLACED';
export const REFUNDED = 'REFUNDED';
export const ON_HOLD = 'ON-HOLD';
export const TRACKED = 'TRACKED';
export const UNASSIGNED = 'UNASSIGNED';
export const ALL = 'ALL';

export const statusList = [
  CANCELLED,
  COMPLETED,
  FAILED,
  ISSUE,
  PENDING,
  PLACED,
  REFUNDED,
  TRACKED,
  UNASSIGNED,
];

// issue types
export const BAD_ADDRESS = 'BAD_ADDRESS';
export const OUTSIDE_MAINLAND = 'OUTSIDE_MAINLAND';
export const REQUESTED_CANCEL = 'REQUESTED_CANCEL';
export const BAD_FITMENT = 'BAD_FITMENT';
export const BAD_SUPPLIER = 'BAD_SUPPLIER';
export const DUPLICATE = 'DUPLICATE';
export const LOST_ITEMS = 'LOST_ITEMS';
export const LOW_PROFIT = 'LOW_PROFIT';
export const OTHER = 'OTHER';
export const OUT_OF_STOCK = 'OUT_OF_STOCK';
export const NEEDS_MANUAL_REVIEW = 'NEEDS_MANUAL_REVIEW';
export const MULTIPLE_SUPPLIER = 'MULTIPLE_SUPPLIER';
export const WPS_WEB_PLACEMENT = 'WPS_WEB_PLACEMENT';
export const BACKORDER_ITEM = 'BACKORDER_ITEM';

export const issueList = [
  BAD_ADDRESS,
  OUT_OF_STOCK,
  LOW_PROFIT,
  REQUESTED_CANCEL,
  BAD_SUPPLIER,
  BAD_FITMENT,
  LOST_ITEMS,
  DUPLICATE,
  OTHER,
  NEEDS_MANUAL_REVIEW,
  MULTIPLE_SUPPLIER,
  WPS_WEB_PLACEMENT,
  BACKORDER_ITEM,
];
