import { useMemo, useEffect, useState, FormEvent } from 'react';
import { useParams } from 'react-router-dom';

import useMeStores from '@src/hooks/swr/useMeStores';
import ChannelCategorySelect from '@src/Components/common/Selects/ChannelCategorySelect';

import {
  Grid,
  CardHeader,
  CardContent,
  makeStyles,
  Modal,
  Typography,
  Button,
  Divider,
  Paper,
  FormControl,
  // Select,
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import type {
  VariationsForm,
  ManufacturerSupplierForm,
  FitmentForm,
  Makes,
  Models,
} from '../../../NewProductForm/types';
import { Params } from '../../types';
import FitmentApi from '@oneAppCore/services/FitmentApi';
// import VariationBox from './variationBox';
import { FontAwesome, TextField, Select } from '@src/Components/common';
import FileUpload from '@src/Components/common/buttons/FileUpload';
import { useSnackbar } from 'notistack';
import StoreSelect from '@src/Components/common/Selects/StoreSelect';
import BrandSelect from '@src/Components/common/Selects/BrandSelect';
import { columns } from './constants';
import type { ParentTextFieldsProps } from './types';
import ImageGallery from 'react-image-gallery';
import ProductApi from '@oneAppCore/services/ProductApi';
import type { ProductForm } from '../types';
import NewCategoryDrawer from './NewCategoryDrawer';
import BrandDrawer from '../../../Brands/BrandDrawer';
import { KeywordOptionType } from '@oneAppCore/types/productTypes';
import useVariationData from '@src/hooks/swr/Inventory/useVariationData';
import { primary } from '@src/Components/Theme/themes/constants';
import CreationDrawer from '@src/Components/common/containers/SearchView/CreationDrawer';

const filter = createFilterOptions<string | KeywordOptionType>();

function ParentTextFields({
  form,
  updateForm,
  isViewMode,
  mutate,
  keywordList,
  nameError,
  storeError,
  brandError,
}: ParentTextFieldsProps) {
  const classes = useStyles();
  const { data: stores = [] } = useMeStores();
  const { id: paramId } = useParams<Params>();
  const { data: variations, mutate: mutateVariations } = useVariationData({
    id: Number(paramId),
  });

  const [store, setStore] = useState<{ label: string; value: number | string }>(
    {
      label: '',
      value: '',
    },
  );
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [modal, setModal] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [categoryForm, setCategoryForm] = useState<ProductForm>({});
  const [value, setValue] = useState<KeywordOptionType | null>(null);
  const [open, setOpen] = useState(false);
  const [brandOpen, setBrandOpen] = useState(false);
  const [brand, setBrand] = useState<string>(null);
  const [newForm, setNewForm] = useState<VariationsForm[]>([]);
  const [fitment, setFitment] = useState<FitmentForm[][]>([[]]);
  const [makes, setMakes] = useState<Makes[]>([]);
  const [models, setModels] = useState<Models[]>([]);
  const [suppliers, setSuppliers] = useState<ManufacturerSupplierForm[]>([]);
  const [savedImages, setSavedImages] = useState([]);
  const [updateId, setUpdateId] = useState(0);
  const [dialogValue, setDialogValue] = useState({
    label: '',
  });
  const [variationImages, setVariationImages] = useState([]);
  const [keywords, updateKeywords] = useState<string[]>([]);

  useEffect(() => {
    if (form.keywords) {
      const newKeywords = form.keywords.split(',');
      updateKeywords(newKeywords);
    }
  }, [isViewMode]);

  const storeOptions = useMemo(
    () =>
      stores.map((store) => ({
        label: store.name,
        value: store.id,
      })),
    [stores],
  );

  const options: KeywordOptionType[] = [{ label: '' }];

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  const close = () => {
    setOpen(false);
  };

  const brandClose = () => {
    setBrandOpen(false);
  };

  const updateBrand = (selectedBrand: string) => {
    setBrand(selectedBrand);
    const updatedVariations = (newForm?.slice() || []).map((variation) => ({
      ...variation,
      brand: selectedBrand,
    }));
    const updatedSuppliers = suppliers.map((supplier) => ({
      ...supplier,
      brand: selectedBrand,
    }));
    setSuppliers(updatedSuppliers);
    setNewForm(updatedVariations);
  };

  const handleMoreImageUpload = async (url: string[]) => {
    await ProductApi.createImage({ imageUrl: url[0] }, form.id)
      .then(() => {
        mutate();
        setSlideIndex(0);
        enqueueSnackbar('Added Image!', { variant: 'success' });
      })
      .catch((e) => enqueueSnackbar(e, { variant: 'error' }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const imagesFromLocalStorage = JSON.parse(
      localStorage.getItem('productImages') || '[]',
    );

    if (imagesFromLocalStorage.length > 0) {
      try {
        await ProductApi.createImage(
          { imageUrl: imagesFromLocalStorage },
          form.id,
        );

        localStorage.removeItem('productImages');

        enqueueSnackbar('Images saved successfully', { variant: 'success' });
      } catch (error) {
        console.error('Error uploading images:', error);
        enqueueSnackbar('Error uploading images', { variant: 'error' });
      }
      localStorage.removeItem('productImages');
    }

    setValue({
      label: dialogValue.label,
    });
  };

  const onChange = <K extends keyof ProductForm>(
    key: K,
    value: ProductForm[K],
  ) => {
    setCategoryForm((previous) => ({
      ...previous,
      [key]: value,
    }));
  };

  useEffect(() => {
    setStore(
      storeOptions.filter((option) => option.value === form?.storeId)[0],
    );
  }, [form]);

  const handleImageClick = () => {
    setModal(true);
    const clickedImage = form?.productImages?.[slideIndex];
    if (clickedImage) {
      const clickedImageId = clickedImage.id;
      setUpdateId(clickedImageId);
    }
  };

  const handleDeletePhoto = async (slideIndex) => {
    const images = form.productImages;
    const deletedImage = images.splice(slideIndex, 1);
    await ProductApi.updateImage({ images }, deletedImage[0].imageId)
      .then(() => {
        mutate();
        setModal(false);
        setSlideIndex(0);
        enqueueSnackbar('Updated images', { variant: 'success' });
      })
      .catch((e) => {
        setModal(false);
        enqueueSnackbar(e, { variant: 'error' });
      });
  };

  const updateUrl = async (url: (string | object)[]) => {
    const imageUrl = typeof url[0] === 'string' ? url[0] : null;

    const imageUrls = form.productImages.map((obj) => {
      if (Array.isArray(obj.imageUrl)) {
        return obj.imageUrl[0];
      }
      return null;
    });
    const newUrlArray = imageUrls.map((u, i) =>
      i === slideIndex ? imageUrl || u : u,
    );
    await ProductApi.updateImage({ images: [imageUrl] }, updateId)
      .then(() => {
        mutate();
        setSlideIndex(0);
        setModal(false);
        enqueueSnackbar('Updated images', { variant: 'success' });
      })
      .catch((e) => {
        setModal(false);
        const errorMessage = e.message || 'An error occurred';
      });
  };

  const handleKeywords = (newKeywords: any) => {
    if (keywords.length < newKeywords.length) {
      updateKeywords(newKeywords);
      updateForm('keywords', newKeywords.join(','));
      mutate();
    } else if (keywords.length > newKeywords.length) {
      updateKeywords(newKeywords);
      updateForm('keywords', newKeywords.join(','));
      mutate();
    }
  };

  const saveImagesToLocalStorage = (images) => {
    const existingImages = localStorage.getItem('productImages') || '[]';
    const updatedImages = JSON.stringify([
      ...JSON.parse(existingImages),
      ...images,
    ]);
    localStorage.setItem('productImages', updatedImages);
    setSavedImages(JSON.parse(updatedImages));
  };

  useEffect(() => {
    const existingImages = localStorage.getItem('productImages');
    if (existingImages) {
      setSavedImages(JSON.parse(existingImages));
    }
  }, []);

  const unique = Array.from(new Set(keywordList));

  useEffect(() => {
    if (!form?.productImages && variations && variations?.length > 0) {
      const variationImageIndex = variations?.findIndex(
        (element) => element.imageUrl,
      );
      if (variationImageIndex > -1) {
        setVariationImages([variations?.[variationImageIndex]?.imageUrl]);
      }
    }
  }, [variations, form]);

  console.log(form, 'form');

  const allImages = [...(form.productImages || []), ...savedImages];

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container style={{ justifyContent: 'space-between' }}>
          {/* <Grid
            item
            container
            xs={12}
            style={{ marginLeft: 30, marginBottom: 10 }}
          >
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
              {form?.name}
            </Typography>
          </Grid> */}
          <Grid
            item
            container
            className={classes.imgContainer}
            alignItems="center"
            justify="center"
            sm={12}
            md={6}
            lg={5}
          >
            <Grid className={classes.largeImage} xs={12} item>
              <Grid className={classes.imageContainer} item xs={12}>
                {form?.productImages && form?.productImages?.length > 0 ? (
                  <ImageGallery
                    showFullscreenButton={true}
                    showNav={true}
                    showPlayButton={false}
                    showBullets={false}
                    showThumbnails={true}
                    // ? Must be formated this way according to the package. Might come in handy later?
                    // items={form.productImages.map((x) => ({
                    //   // ? x is the whole image object.
                    //   original: x.imageUrl,
                    //   thumbnail: x.imageUrl,
                    //   thumbnailHeight: '80px',
                    //   originalHeight: '400px',
                    //   maxWidth: '400px',
                    //   whiteSpace: 'normal',
                    // }))}
                    items={allImages.map((image, index) => {
                      if (typeof image === 'object' && image.imageUrl) {
                        return {
                          original: image.imageUrl[0], // Assuming imageUrl is an array
                          thumbnail: image.imageUrl[0],
                          thumbnailHeight: '80px',
                          originalHeight: '400px',
                          maxWidth: '400px',
                          whiteSpace: 'normal',
                        };
                      } else if (typeof image === 'string') {
                        return {
                          original: image,
                          thumbnail: image,
                          thumbnailHeight: '80px',
                          originalHeight: '400px',
                          maxWidth: '400px',
                          whiteSpace: 'normal',
                        };
                      } else {
                        // Handle other cases if necessary
                        return null; // Or any other default value
                      }
                    })}
                    style={{ width: '100px' }}
                    onSlide={(index) => setSlideIndex(index)}
                    onClick={handleImageClick}
                  />
                ) : variationImages.length > 0 ? (
                  <ImageGallery
                    showFullscreenButton={true}
                    showNav={true}
                    showPlayButton={false}
                    showBullets={false}
                    showThumbnails={true}
                    // ? Must be formated this way according to the package. Might come in handy later?
                    items={variationImages.map((imageUrl) => ({
                      // ? x is the whole image object.
                      original: imageUrl,
                      thumbnail: imageUrl,
                      thumbnailHeight: '80px',
                      originalHeight: '400px',
                      maxWidth: '400px',
                      whiteSpace: 'normal',
                    }))}
                    style={{ width: '100px' }}
                    onSlide={(index) => setSlideIndex(index)}
                    onClick={handleImageClick}
                  />
                ) : savedImages.length > 0 ? (
                  <ImageGallery
                    showFullscreenButton={true}
                    showNav={true}
                    showPlayButton={false}
                    showBullets={false}
                    showThumbnails={true}
                    // ? Must be formated this way according to the package. Might come in handy later?
                    items={savedImages.map((imageUrl) => ({
                      // ? x is the whole image object.
                      original: imageUrl,
                      thumbnail: imageUrl,
                      thumbnailHeight: '80px',
                      originalHeight: '400px',
                      maxWidth: '400px',
                      whiteSpace: 'normal',
                    }))}
                    style={{ width: '100px' }}
                    onSlide={(index) => setSlideIndex(index)}
                    onClick={handleImageClick}
                  />
                ) : (
                  <FontAwesome
                    color="grey"
                    name="images"
                    type="far"
                    form="fa"
                    onClick={handleImageClick}
                  />
                )}
              </Grid>
              {isViewMode ? null : (
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  style={{ marginBottom: 20 }}
                >
                  <Grid item>
                    <FileUpload
                      id="addNewImage"
                      onUpload={(url) => {
                        !paramId
                          ? saveImagesToLocalStorage(url)
                          : // : handleMoreImageUpload(url);
                            handleMoreImageUpload(url);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Modal
            open={modal}
            onClose={() => setModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: 500,
                backgroundColor: 'white',
                padding: 20,
                borderRadius: 10,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 10,
                }}
              >
                {form.image && (
                  <img
                    src={form.image[slideIndex]}
                    alt=""
                    width={400}
                    height={400}
                    // onClick={showModal}
                  />
                )}
              </div>
              <Grid
                item
                container
                xs={12}
                direction="row"
                style={{ justifyContent: 'space-between', marginTop: 20 }}
              >
                <Grid item xs={12} style={{ marginBottom: 15 }}>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                  {isViewMode ? null : (
                    <FileUpload
                      id="productUpload"
                      onUpload={(url: string[]) => updateUrl(url)}
                      buttonText="Upload New Photo"
                    />
                  )}
                </Grid>
                <Grid
                  item
                  container
                  xs={6}
                  style={{ justifyContent: 'flex-end' }}
                >
                  <Button
                    style={{ backgroundColor: 'red', color: 'white' }}
                    onClick={() => handleDeletePhoto(slideIndex)}
                  >
                    Delete Image
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Modal>

          {isViewMode ? (
            <Grid
              container
              item
              direction="column"
              xs={12}
              md={6}
              lg={6}
              spacing={2}
              style={{
                // padding: 10,
                marginTop: 1,
                // border: 'lightGrey 1px solid',
                // borderRadius: 10,
              }}
            >
              {/* <Paper
                elevation={1}
                style={{
                  padding: 15,
                  border: '1px solid #eeeeee',
                  borderRadius: '8px',
                }}
              > */}
              {/* <CardHeader
                titleTypographyProps={{
                  variant: 'h6',
                  style: {
                    fontWeight: 500,
                  },
                }}
                title="Product Information"
              />
              <Divider style={{ width: 'fullWidth', marginBottom: 10 }} /> */}
              {columns.map((column) => {
                if (column.keyName === 'storeId') {
                  return (
                    <Grid
                      item
                      key={column.keyName}
                      className={classes.productInfoColor}
                      // xs={12}
                    >
                      <Grid item container xs={12}>
                        <Grid xs={5}>
                          <Typography style={{ fontSize: '20px' }}>
                            {column.title}:
                          </Typography>
                        </Grid>
                        <Grid xs={7}>
                          <Typography style={{ fontSize: '20px' }}>
                            {store?.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                }
                return (
                  <Grid
                    item
                    key={column.keyName}
                    className={classes.productInfoColor}
                    // style={{ maxHeight: '65px' }}
                  >
                    <Grid item container xs={12}>
                      {column.customComponent ? (
                        <column.customComponent
                          keyValue={form[column.keyName]}
                          form={form}
                        />
                      ) : (
                        // <Grid item container style={{ width: 'max-content' }}>
                        <>
                          <Grid xs={5}>
                            <Typography
                              style={{ fontSize: '20px', marginBottom: 10 }}
                            >
                              {column.title}:
                            </Typography>
                          </Grid>
                          <Grid xs={7}>
                            <Typography
                              style={{ fontSize: '20px', marginBottom: 10 }}
                            >
                              {form[column.keyName]
                                ? form[column.keyName]
                                : null}
                            </Typography>
                          </Grid>
                        </>
                        // {/* // </Grid> */}
                      )}
                    </Grid>
                  </Grid>
                );
              })}
              {/* </Paper> */}
            </Grid>
          ) : (
            <Grid
              container
              item
              direction="row"
              sm={12}
              md={6}
              lg={6}
              spacing={2}
              // style={{ marginTop: '15px', alignContent: 'space-evenly' }}
              // style={{
              //   marginTop: 1,
              //   alignContent: 'stretch',
              //   border: 'lightGrey 1px solid',
              //   borderRadius: 10,
              // }}
            >
              {/* <Paper
                elevation={1}
                style={{
                  padding: 15,
                  border: '1px solid #eeeeee',
                  borderRadius: '8px',
                }}
              > */}

              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  fullWidth
                  // error={form?.name === undefined || form?.name?.length < 1}
                  error={nameError}
                  label="Product Name"
                  name="name"
                  value={form?.name || ''}
                  onChange={(e) => updateForm('name', e.target.value)}
                  required
                  style={{
                    backgroundColor: primary.transparent.main,
                  }}
                />
              </Grid>
              <Grid item container xs={12} style={{ alignItems: 'center' }}>
                <Grid item xs={10} style={{ marginBottom: 10 }}>
                  <BrandSelect
                    brandError={brandError}
                    value={form?.brandId || ''}
                    onChange={(option) => updateForm('brandId', option.value)}
                    required
                    // style={{
                    backgroundColor={primary.transparent.main}
                    // }}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={2}
                  style={{ justifyContent: 'flex-end', maxHeight: 30 }}
                >
                  <CreationDrawer Form={BrandDrawer} />
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 5 }}>
                <Select
                  label="Store"
                  storeError={storeError}
                  containerClass="store-id-select"
                  value={store}
                  onChange={(option) => updateForm('storeId', option.value)}
                  required
                  options={storeOptions}
                  style={{ backgroundColor: primary.transparent.main }}
                />
              </Grid>

              <Grid item xs={12} style={{ marginBottom: 5 }}>
                <ChannelCategorySelect
                  onChange={(id) => updateForm('channelCategoryId', id)}
                  value={(form?.channelCategoryId as number) ?? null}
                  backgroundColor={primary.transparent.main}
                />
                {/* <Button>Test</Button> */}
              </Grid>
              <Grid
                item
                container
                xs={12}
                style={{ marginBottom: 5, justifyContent: 'flex-end' }}
              >
                <Button
                  color="secondary"
                  variant="outlined"
                  size="small"
                  style={{ marginTop: 10, marginBottom: 5 }}
                  onClick={() => setOpen(true)}
                >
                  Add New Category
                </Button>
                <NewCategoryDrawer isDrawerOpen={open} setDrawerOpen={close} />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  freeSolo
                  value={keywords}
                  filterOptions={(options, params) => {
                    const filtered = filter(
                      options,
                      params,
                    ) as KeywordOptionType[];

                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        label: `Add "${params.inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  id="tags-standard"
                  options={unique}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.label;
                  }}
                  onChange={(event, value) => {
                    handleKeywords(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Keywords"
                      placeholder="Keywords"
                      style={{
                        backgroundColor: primary.transparent.main,
                        minWidth: '450px',
                      }}
                      fullWidth
                      // onKeyDown={(e) => {
                      //   handleKeyPress(e);
                      // }}
                    />
                  )}
                />
              </Grid>
              {/* </Paper> */}
            </Grid>
          )}
          {/* <Grid item xs={12}>
            <Divider style={{ marginTop: 70 }} />
          </Grid> */}
        </Grid>
      </form>
    </>
  );
}

export default ParentTextFields;

const useStyles = makeStyles({
  underlineBorder: {
    paddingBottom: 30,
    paddingTop: 50,
    borderBottom: '1px solid lightgrey',
  },
  imgContainer: {
    padding: 15,
    paddingBottom: 0,
    marginRight: 20,
  },
  largeImage: {
    height: '100%',
  },
  imgInput: {
    display: 'none',
  },
  addImageContainer: {
    fontSize: 30,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    border: '1px dashed grey',
    padding: '20px',
    borderRadius: '4px',
    color: 'grey',
    '&:hover': {
      color: 'darkgrey',
      cursor: 'pointer',
      border: '1px dashed darkgrey',
    },
  },

  addImageIcon: {
    color: '#3A9ADD',
    '&:hover': {
      color: '#2C7BB1',
    },
  },

  image: {
    height: 100,
    padding: '0px',
    margin: 12,
  },
  imageContainer: {
    fontSize: 70,
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
  productInfoColor: {
    fontWeight: 200,
    color: 'grey',
  },
});
