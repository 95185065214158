import {
  Grid,
  Typography,
  Select,
  MenuItem,
  TextField,
  FormControl,
  OutlinedInput,
} from '@material-ui/core';

import OrderRestockInput from './OrderRestockInput';

import dateUtil from '@src/utils/dates';
import ViewDrawerButton from './ViewDrawerButton';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { useHistory } from 'react-router-dom';
import { formatCentsToDollars } from '@oneAppCore/utils/currency';
import type { Column } from '@src/Components/common/containers/SearchView/types';
import {
  getColor

} from '@src/utils/colors';
import { supplierIds } from '@oneAppCore/constants/internalvdropship';
import { RMATV, WPSIR, AD, MONARCH } from '@oneAppCore/constants/suppliers';
export const columns = (
  supplier: any,
  lastPO: number = 0,
  defaultPO: number = 0,
  poList: any[] = [],
  resetPO: any,
  changePO: any,
  handleSuppChange: any,
): Column[] => [
    {
      keyName: 'image',
      title: 'Image',
      align: 'left',
      customComponent: function InventoryImage(row) {
        if (!row.images?.[0]?.imageUrl) return <></>;
        return (
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <img
                height={55}
                width={55}
                src={row.images?.[0]?.imageUrl}
                alt="Product"
              />
            </Grid>
          </Grid>
        );
      },
    },
    {
      keyName: 'manufacturerNo',
      title: 'MPN (SKU)',
      sortBy: true,
      align: 'center',
      customComponent: function currentInbound(row) {
        return (
          <>
            {row.manufacturerNo}
            <br />
            ({row.sku})
          </>
        )
      }
    },
    {
      keyName: 'brand',
      title: 'Brand',
      sortBy: true,
      align: 'center',
    },
    {
      keyName: 'daysLeft',
      title: 'Days Left',
      sortBy: true,
      align: 'center',
      customComponent: function currentInbound(row) {
        const { daysLeft } = row;
        const color = daysLeft === 0 ? getColor('dark_red', 'main')
          : daysLeft < 3 ? getColor('orange', 'main')
            : getColor('dark_green', 'main');
        return (
          <Typography style={{ color: color, fontWeight: 'bold' }}>
            {daysLeft}
          </Typography>
        )
      }
    },
    {
      keyName: 'qtyNeeded',
      title: 'Order QTY',
      sortBy: true,
      align: 'center',
      customComponent: function customInput(row, index, updateFunction) {
        return (
          <Grid item>
            <FormControl style={{ margin: 1, width: 100 }}>
              <OutlinedInput
                id="standard-adornment-password"
                type="number"
                value={row.qtyNeeded}
                error={!row.qtyNeeded || row.qtyNeeded < 1}
                onChange={(e) => {
                  let value = e.currentTarget.value;
                  updateFunction({ ...row, qtyNeeded: parseInt(value) }, index)
                }}
              />
            </FormControl>
          </Grid>
        );
      },
    },
    {
      keyName: 'quantity',
      title: 'Supp. QTY',
      align: 'center',
      customComponent: function orderCount(row) {
        const selectedSupplier = row?.supplierData?.find((s) => s?.supplierId == parseInt(row?.selectedSupplier));
        const quantity = selectedSupplier ? selectedSupplier.quantity : 0;
        const color = quantity <= 10 ? getColor('dark_red', 'main')
          : quantity <= 20 ? getColor('orange', 'dark')
            : getColor('dark_green', 'main');
        return (
          <Grid item style={{ minWidth: 100, color: color }}>
            {selectedSupplier && (
              <Typography>
                {selectedSupplier.quantity}
              </Typography>
            )}
          </Grid>
        );
      },
    },{
      keyName: 'salesInterval',
      title: 'Orders/Qty 7 Days',
      sortBy: true,
      align: 'center',
      customTitle: function orderCountColumn(column) {
        return (
          <div>Ord/Qty ({column?.purchDateAmount ? column?.purchDateAmount : '10'})</div>
        );
      },
      customComponent: function orderCount(row) {
        return (
          <Grid>
            <Typography>
              {row.orderCount || 0} / {row.salesInterval || 0}
            </Typography>
          </Grid>
        );
      },
    },
    {
      keyName: 'quantity',
      title: 'CRT (INB)',
      sortBy: true,
      align: 'center',
      customComponent: function currentInbound(row) {
        const { quantity, virtualQty } = row;
        const currentColor = quantity === 0 ? getColor('red', 'dark')
          : quantity < 5 ? getColor('orange', 'dark')
            : getColor('dark_purple', 'dark');
        const inboundColor = virtualQty === 0 ? getColor('red', 'dark')
          : virtualQty < 5 ? getColor('orange', 'dark')
            : getColor('black', 'dark');
        return (
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item style={{ marginRight: '3px', color: currentColor }}>
              <Typography >
                {quantity}
              </Typography >
            </Grid>
            <Grid item style={{ marginLeft: '3px' }}>
              <Typography style={{ fontWeight: 'bold', color: inboundColor }}>
                ({virtualQty})
              </Typography>
            </Grid>
          </Grid>
        )
      }
    },
    {
      keyName: 'cost',
      title: 'Cost',
      align: 'center',
      customComponent: function orderCount(row) {
        const selectedSupplier = row?.supplierData?.find((s) => s?.supplierId == parseInt(row?.selectedSupplier));
        return (
          <Grid item style={{ minWidth: 100 }}>
            {selectedSupplier && (
              <Typography>
                <p style={{ color: 'black' }}>
                  {formatCentsToDollars(selectedSupplier.cost)}
                </p>
              </Typography>
            )}
          </Grid>
        );
      },
    },
    {
      title: 'Supplier',
      keyName: 'selectedSupplier',
      sortBy: true,
      align: 'center',
      customComponent: function supps(row, index, updateFunction) {
        const suppOptions = row?.supplierData;
        const getSupplierColor = (supplierId) =>
          supplierId === supplierIds[RMATV] ? getColor('dark_purple', 'main')
            : supplierId === supplierIds[WPSIR] ? getColor('red', 'main')
              : supplierId === supplierIds[AD] ? getColor('dark_blue', 'dark')
                : supplierId === supplierIds[MONARCH] ? getColor('orange', 'dark')
                  : getColor('grey', 'main');
        return (
          <>
            <Select
              onChange={(event) => {
                let value = event.target.value;
                updateFunction({ ...row, selectedSupplier: value }, index)
              }}
              defaultValue={row?.selectedSupplier ? row?.selectedSupplier : 'No Suppliers'}
            >
              {suppOptions?.map((supplier) =>
                <MenuItem key={supplier?.mid} value={supplier?.supplierId} style={{ color: `${getSupplierColor(supplier?.supplierId as number)}` }}>
                  <Typography style={{ color: `${getSupplierColor(supplier?.supplierId)}` }} >

                    {supplier?.name || 'Unknown Supplier'}
                  </Typography>
                </MenuItem>
              )}
            </Select>
          </>
        );
      },
    },
    {
      keyName: 'restockedAt',
      title: 'Last Ordered',
      sortBy: true,
      align: 'center',
      customComponent: function restockAmount(row) {
        const purchaseHistory = row?.purchaseHistory || [];
        const itemPurchaseHistory = purchaseHistory?.filter((item) => item && item?.manufacturerId === row.id);
        const purchaseHistoryLength = itemPurchaseHistory?.length < 2 ? 2 : itemPurchaseHistory?.length;
        const limitedPurchaseHistory = itemPurchaseHistory?.slice(purchaseHistoryLength - 2);
        return (
          <>
            {limitedPurchaseHistory?.map((restock, i) => (
              <Typography key={i}>
                {restock?.amountPurchased && <b>{restock?.amountPurchased}</b>}{' '}
                {restock?.amountPurchased && 'on'}{' '}
                {restock?.purchasedAt &&
                  dateUtil.getFormattedDate({ date: restock?.purchasedAt })}
              </Typography>
            ))}
          </>
        );
      },
    },
    {
      keyName: 'actions',
      title: 'Actions',
      customComponent: function ActionButtons(row) {
        const history = useHistory();
        const { id } = row;
        return (
          <Grid container xs={12}>
            <Grid item>
              <ViewDrawerButton id={row.id} row={row} />
            </Grid>
          </Grid>
        );
      },
    },
  ];
