import dayjs from 'dayjs';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getColor } from '@src/utils/colors';
import numberUtils from '@oneAppCore/utils/numbers';
import ViewDrawerButton from './ViewDrawerButton';
import EditButton from './EditButton';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Grid,
  TextField,
  InputAdornment,
  Typography,
  ListItem,
  Tooltip,
  Button,
  Link,
  TableBody,
} from '@material-ui/core';
import type {
  Column,
  ExportOption,
} from '@src/Components/common/containers/SearchView/types';
import FontAwesome from '@src/Components/common/FontAwesome';
import { dateFormat } from '@oneAppCore/constants/dates';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import {
  ACTIVE,
  DRAFT,
  INACTIVE,
  PENDING,
  REJECTED,
} from '@oneAppCore/constants/listings';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';
import { BOOTSTRAP, GREENS, ORANGES, REDS } from '@oneAppCore/constants/colors';
import {
  AMAZON,
  AMAZON_ASIN_LINK,
  WALMART,
  WALMART_PRODUCT_LINK,
  EBAY,
  EBAY_PRODUCT_LINK,
  channelIds,
} from '@oneAppCore/constants/channels';
import { RMATV, WPS, AD } from '@oneAppCore/constants/suppliers';

export const columns = (
  changePriceFunc,
  tableRowsState,
  classes,
  // selectRow,
  // deselectRow,
): Column[] => [
  {
    title: 'Image',
    keyName: 'thumbnail',
    sortBy: false,
    align: 'center',
    customComponent: function ImageRender(row) {
      return (
        <img
          src={
            row.listingImages?.length
              ? row.listingImages[0]
              : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;
          }}
          alt=""
          width={50}
          height={50}
        />
      );
    },
  },
  {
    title: 'Listing',
    keyName: 'name',
    sortBy: false,
    align: 'left',
    customComponent: function NameRender(row) {
      return (
        <Grid
          container
          style={{
            flexDirection: 'column',
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '18px',
            minWidth: '175px',
          }}
        >
          <Grid item>
            {row.name}
            {row.variation ? ` - (${row.variation})` : ''}
          </Grid>
          <Grid item>
            <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
              Channel ID:
            </span>
            {row?.channelProductId}
          </Grid>
          {/* <Grid>
            {row.id}
          </Grid> */}
          <Grid item>
            <span style={{ fontWeight: 'bold', marginRight: '3px' }}>SKU:</span>
            {row?.listingSku}
          </Grid>
        </Grid>
      );
    },
  },
  {
    title: 'Created At',
    keyName: 'createdAt',
    sortBy: true,
    align: 'center',
    customComponent: function DateRender(row) {
      return (
        <p style={{ margin: 0 }}>{dayjs(row.createdAt).format(dateFormat)}</p>
      );
    },
  },
  {
    title: 'Channel',
    keyName: 'channelName',
    align: 'center',
    customComponent: function Channel(row) {
      let href = '';
      if (row?.channelProductId) {
        if (row?.channelId === channelIds[AMAZON]) {
          href = AMAZON_ASIN_LINK(row?.channelProductId);
        } else if (row?.channelId === channelIds[WALMART]) {
          href = WALMART_PRODUCT_LINK(row?.channelProductId);
        } else if (row?.channelId === channelIds[EBAY]) {
          href = EBAY_PRODUCT_LINK(row?.channelProductId);
        }
      }
      return (
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 5,
          }}
        >
          <img
            width={35}
            height={35}
            style={{ borderRadius: 10 }}
            src={
              row.icon != null
                ? row.icon
                : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
            }
          />
          {href !== '' && (
            <Link href={href} target="_blank">
              <FontAwesome name="external-link-alt" type="fa" form="fa" />
            </Link>
          )}
        </Grid>
      );
    },
  },
  {
    title: 'Price',
    keyName: 'itemPrice',
    sortBy: true,
    align: 'center',
    customComponent: function Price(row) {
      return (
        <Grid item>
          <Typography>{formatCentsToDollarsIntl(row.price)}</Typography>
        </Grid>
      );
    },
  },
  {
    title: 'Cost',
    keyName: 'cost',
    sortBy: true,
    align: 'center',
    customComponent: function cost(row) {
      const { supplierArray } = row;
      const lowestCostArray = supplierArray?.map((supplier) =>
        supplier.estimatedShipping > 0 &&
        supplier.estimatedShipping < supplier.shippingCost
          ? supplier.cost + supplier.estimatedShipping
          : supplier.cost + supplier.shippingCost,
      );
      const lowestSupplierCost = Array.isArray(lowestCostArray)
        ? formatCentsToDollarsIntl(Math.min(...lowestCostArray))
        : formatCentsToDollarsIntl(row.lowcost);

      return (
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={
            <TableContainer className={classes.tablePaper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Supplier</TableCell>
                    <TableCell>Cost</TableCell>
                    <TableCell>Act. Ship</TableCell>
                    <TableCell>Est. Ship</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {supplierArray &&
                    supplierArray.length >= 1 &&
                    supplierArray.map((supplier, index) => (
                      <TableRow key={index}>
                        <TableCell classes={{ root: classes.tableCell }}>
                          {supplier?.metaData?.shortName ||
                            supplier.name
                              .match(/\b(\w)/g)
                              .join('')
                              .toUpperCase()}
                        </TableCell>
                        <TableCell>
                          {formatCentsToDollarsIntl(supplier.cost)}
                        </TableCell>
                        <TableCell>
                          {formatCentsToDollarsIntl(supplier.shippingCost)}
                        </TableCell>
                        <TableCell>
                          {formatCentsToDollarsIntl(supplier.estimatedShipping)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        >
          <Grid item>
            <Typography style={{ maxWidth: '120px' }}>
              {lowestSupplierCost}
            </Typography>
          </Grid>
        </Tooltip>
      );
    },
  },

  {
    title: 'Profit',
    keyName: 'profit',
    sortBy: true,
    align: 'center',
    customComponent: function cost(row) {
      return (
        <Grid item>
          <Typography style={{ maxWidth: '120px' }}>
            {formatCentsToDollarsIntl(row.profit)}
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: 'Inventory',
    keyName: 'quantity',
    align: 'center',
  },
  {
    title: 'Created By',
    keyName: 'createdByName',
    sortBy: true,
    align: 'center',
  },
  /* {
        title: 'Sales Last 14 Days (Now/Prev.)',
        keyName: 'sales_14_days',
        sortBy: false, // change back once sales 14 days is less memory intensive
        align: 'center',
        customComponent: function Sales(row) {
          const sales = (row.orderItems || []).reduce((prev, curr) => prev + curr.itemPrice, 0);
          const prevSales = (row.orderItemsPrev || []).reduce((prev, curr) => prev + curr.itemPrice, 0);
          const color = sales > prevSales ? GREENS.dark : sales === prevSales ? ORANGES.dark : REDS.dark;
          return (
            <>
              <Grid item>
                {<Typography>
                  <span style={{ color }}>{formatCentsToDollarsIntl(sales)}</span>&nbsp;
                  / {formatCentsToDollarsIntl(prevSales)}
                </Typography>}
              </Grid>
            </>
          );
        },
      }, */
  {
    title: 'Status',
    keyName: 'status',
    sortBy: true,
    align: 'center',
    customComponent: function StatusRender(row) {
      const { status } = row;
      const background =
        status === DRAFT
          ? BOOTSTRAP.v0.warning.color
          : status === INACTIVE
          ? BOOTSTRAP.v0.dark.color
          : status === PENDING
          ? BOOTSTRAP.v0.light.color
          : status === ACTIVE
          ? BOOTSTRAP.v0.success.color
          : status === REJECTED
          ? BOOTSTRAP.v0.danger.color
          : BOOTSTRAP.v0.light.color;
      return (
        <Grid item>
          <p
            style={{
              background,
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '18px',
              padding: '1px 9px',
              color: 'white',
              borderRadius: '17px',
              textAlign: 'center',
            }}
          >
            {row?.status?.charAt(0).toUpperCase() + row?.status?.slice(1)}
          </p>
        </Grid>
      );
    },
  },
  {
    keyName: 'actions',
    title: 'Actions',
    customComponent: function ActionButtons(row) {
      console.log(row, 'listing row');
      return (
        <Grid container xs={12}>
          <Grid item xs={6}>
            <EditButton row={row} />
          </Grid>
          <Grid item xs={6}>
            <ViewDrawerButton id={row.id} row={row} />
          </Grid>
        </Grid>
      );
    },
  },
];
