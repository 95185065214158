import {
  TableCell,
  TableRow,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  Grid,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { listingTable } from './constants';
import type { Listings, ListingProps, ListingConstants } from './types';
import FontAwesome from '@src/Components/common/FontAwesome';
import Api from '@oneAppCore/services/Api';
import { DRAFT } from '@oneAppCore/constants/listings';
import { convertDollarsToCents } from '@src/utils/currency';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { formatCentsToDollars } from '@oneAppCore/utils/currency';
import StoreListingApi from '@oneAppCore/services/StoreListingApi';
import ManufacturerSuppliersApi from '@oneAppCore/services/ManufacturerSuppliersApi';
import { primary } from '@src/Components/Theme/themes/constants';
import {
  AMAZON,
  AMAZON_ASIN_LINK,
  WALMART,
  WALMART_PRODUCT_LINK,
  EBAY,
  EBAY_PRODUCT_LINK,
  channelIds,
} from '@oneAppCore/constants/channels';

const useStyles = makeStyles(() => ({
  headerColor: {
    border: 0,
    margin: 5,
  },
  fontColor: {
    color: '#FFF',
    backgroundColor: '#6e6b7b',
    fontSize: 10,
  },
  bodyFont: {
    fontSize: 12,
  },
  tableContainer: {
    maxHeight: 440,
    zIndex: 2,
    margin: 10,
    borderRadius: 5,
    border: '1px solid #6e6b7b',
  },
}));

const ListingTable = ({
  listingData,
  variationId,
  mutate,
  manufacturerNo,
  supplierData,
  discountData,
}: ListingProps) => {
  const [enableEdit, setEnableEdit] = useState<number | null>(null);
  const [stores, setStores] = useState([]);
  const [saving, setSaving] = useState(false);

  const { id }: any = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [editListing, setEditListing] = useState({
    id: 0,
    listingSku: '',
    price: 0,
    minPrice: 0,
    maxPrice: 0,
  });

  const classes = useStyles();

  useEffect(() => {
    const getStoreChannels = async () =>
      await Api.get(`/api/v1/store-channels/search`).then((res) => {
        const { rows } = res;
        setStores(rows);
      });
    getStoreChannels();
  }, []);

  const handleChange = (key, e) => {
    setEditListing((list) => {
      const updatedListing = {
        ...list,
        [key.keyName]: e.target.value,
      };
      return updatedListing;
    });
  };

  const invokeListingUpdate = async (id, fitIndex) => {
    setSaving(true);
    if (editListing.listingSku === null) {
      enqueueSnackbar('Please fill out all fields!', {
        variant: 'error',
      });
    } else {
      // const storeChannelId = ;
      const listingSku = editListing.listingSku;
      const originalPrice = listingData[fitIndex].price;
      const originalMin = listingData[fitIndex].minPrice;
      const originalMax = listingData[fitIndex].maxPrice;
      const newMin = editListing.minPrice;
      const newMax = editListing.maxPrice;
      const newPrice = editListing.price;
      const maxPrice =
        originalMax !== newMax ? convertDollarsToCents(newMax) : newMax;
      const minPrice =
        originalMin !== newMin ? convertDollarsToCents(newMin) : newMin;
      const price =
        originalPrice !== newPrice ? convertDollarsToCents(newPrice) : newPrice;

      const payload = {
        // storeChannelId,
        listingSku,
        status: DRAFT,
        price,
        minPrice,
        maxPrice,
      };

      try {
        await Api.put(`/api/v1/store-listings/${editListing.id}`, payload);
        enqueueSnackbar('Listing Updated!', {
          variant: 'success',
        });
        mutate();
        setEditListing(editListing);
      } catch (e) {
        enqueueSnackbar(`${e.message || e}`, {
          variant: 'error',
        });
      } finally {
        setEnableEdit(null);
        setSaving(false);
        setEditListing({
          id: 0,
          listingSku: '',
          price: 0,
          minPrice: 0,
          maxPrice: 0,
        });
      }
    }
  };

  const enableEditing = (fitIndex: number) => {
    setEditListing({
      id: listingData[fitIndex].id,
      listingSku: listingData[fitIndex].listingSku,
      price: listingData[fitIndex].price,
      minPrice: listingData[fitIndex].minPrice,
      maxPrice: listingData[fitIndex].maxPrice,
    });
    setEnableEdit(fitIndex);
  };

  const lowestSupp = supplierData
    ? supplierData?.reduce((lowest, supplier) => {
        const combinedCost = supplier.cost + supplier.shippingCost;
        if (!lowest || (combinedCost && combinedCost < lowest.combinedCost)) {
          return { supplier, combinedCost };
        }
        return lowest;
      }, null)
    : null;

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader size="small" aria-label="fitment">
        <TableHead>
          <TableRow className={classes.headerColor}>
            {listingTable.map((listing: ListingConstants, i) => (
              <TableCell
                className={classes.fontColor}
                key={'listing_' + listing.keyName + i}
              >
                {listing.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {listingData &&
          listingData.map((listing: Listings, fitIndex) => {
            const isEditing = enableEdit === fitIndex;
            return (
              <TableRow key={listing.id}>
                {listingTable.map((key: ListingConstants, index) => {
                  if (isEditing && key.keyName === 'store') {
                    return (
                      <TableCell key={`listing_${key.keyName}_${listing.id}`}>
                        {/* <Select
                        value={editListing.store}
                        onChange={(e) =>
                          handleChange({ keyName: 'store' }, e)
                        }
                      >
                        <MenuItem value={'0'}></MenuItem>
                        {stores.map((s) => (
                          <MenuItem value={s.id} key={s.storeId}>
                            {s.storeName}
                          </MenuItem>
                        ))}
                      </Select> */}
                        <Typography>{listing.store}</Typography>
                      </TableCell>
                    );
                  } else if (key.keyName === 'channel') {
                    let href = '';
                    if (listing.channelId === channelIds[AMAZON]) {
                      href = AMAZON_ASIN_LINK(listing?.channelProductId);
                    } else if (listing.channelId === channelIds[WALMART]) {
                      href = WALMART_PRODUCT_LINK(listing?.channelProductId);
                    } else if (listing.channelId === channelIds[EBAY]) {
                      href = EBAY_PRODUCT_LINK(listing?.channelProductId);
                    }
                    const handleChannelClick = (href) => {
                      window.open(href, '_blank');
                    };
                    return (
                      <TableCell key={`listing_${key.keyName}_${listing.id}`}>
                        <Tooltip title={`View Listing on ${listing.channel}`}>
                          <img
                            width={35}
                            height={35}
                            style={{ borderRadius: 10 }}
                            src={listing?.icon}
                            onClick={() => href && handleChannelClick(href)}
                          />
                        </Tooltip>
                      </TableCell>
                    );
                  } else if (key.keyName === 'edit') {
                    return (
                      <TableCell key={'listing_' + key.keyName + listing.id}>
                        <FontAwesome
                          name={isEditing ? 'check' : 'edit'}
                          color={isEditing ? '#50C87' : primary.main}
                          type="fas"
                          form="fa"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            isEditing
                              ? invokeListingUpdate(listing.id, fitIndex)
                              : enableEditing(fitIndex);
                          }}
                        />
                      </TableCell>
                    );
                  } else if (key.keyName === 'cost') {
                    return (
                      <TableCell key={'listing_' + key.keyName + listing.id}>
                        <Tooltip
                          style={{
                            width: '425px',
                            borderRadius: '10px',
                            justifyContent: 'flex-start',
                          }}
                          title={
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Supplier</TableCell>
                                    <TableCell>Cost</TableCell>
                                    <TableCell>Act. Ship</TableCell>
                                    <TableCell>Est. Ship</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {supplierData?.map((supp) => (
                                    <TableRow
                                      style={{ backgroundColor: '#ffffff' }}
                                      key={supp.supplierId}
                                    >
                                      <TableCell>{supp.supplier}</TableCell>
                                      <TableCell>
                                        {formatCentsToDollars(supp.cost)}
                                      </TableCell>
                                      <TableCell>
                                        {formatCentsToDollars(
                                          supp.shippingCost,
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {formatCentsToDollars(
                                          supp.estimatedShipping,
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          }
                        >
                          <Grid item>
                            <Typography style={{ fontSize: '12px' }}>
                              {formatCentsToDollars(lowestSupp?.combinedCost)}
                            </Typography>
                          </Grid>
                        </Tooltip>
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        className={classes.bodyFont}
                        key={'listing_' + key.keyName + listing.id}
                      >
                        {isEditing && key.editable !== false ? (
                          <TextField
                            variant="outlined"
                            size="small"
                            defaultValue={listing[key.keyName] ?? ''}
                            onChange={(e) => handleChange(key, e)}
                          />
                        ) : key.customComponent ? (
                          key.customComponent(listing)
                        ) : (
                          listing[key.keyName]
                        )}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            );
          })}
      </Table>
    </TableContainer>
  );
};

export default ListingTable;
