import { useEffect, useState } from 'react';
import recoilSearch from './hooks/useSearchOptions';
import useSearch from './hooks/useSearch';
import { useRecoilState, useRecoilValue } from 'recoil';
import { reposState as reposAtom } from './SearchTable/atoms';

import { searchOptionsDefault as searchViewDefaultOptions } from './hooks/useSearchOptions/constants';

import { Grid } from '@material-ui/core/';

import MiniReports from './MiniReports';
import ChannelOptions from './ChannelOptions';
import SearchTable from './SearchTable';
import CreationDrawer from './CreationDrawer';

import type { Props } from './types';

function SearchView({
  url,
  showChannelOptions,
  supportsDownload,
  MiniReportComponent,
  filters,
  constantFilters,
  columns,
  childColumns,
  CreateFormComponent,
  exportOptions,
  selectTypes = [],
  select = false,
  orderBy,
  carrierData,
  mutateValue: mutateProp = 0,
  me,
  swrOptions = {
    revalidateOnFocus: true,
    focusThrottleInterval: 3500,
  },
  customSearchBarComponents,
}: Props) {
  const [, setSearchOptions] = recoilSearch.useState();
  const { data, mutate } = useSearch(swrOptions.revalidateOnFocus, false, swrOptions.focusThrottleInterval);
  const [mutateValue, setMutateValue] = useState<number>(0);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});

  const [selectedTableRows, setSelectedTableRows] = useRecoilState(reposAtom);
  const selectedRows = useRecoilValue(reposAtom);

  const [openTableRows, setOpenTableRows] = useState([]);
  const toggleRow = (rowId) => {
    const index = openTableRows.indexOf(rowId);
    if (index > -1) {
      setOpenTableRows((openRows) => openRows.filter((openRows) => openRows !== rowId));
    } else {
      setOpenTableRows((openRows) => [...openRows, rowId]);
    }
  };

  const selectRow = (props: any) => {
    setSelectedTableRows((selectedRows) => {
      return [...selectedRows, props];
    });
  };
  const deselectRow = (props: any) => {
    setSelectedTableRows((selectedRows) => {
      return selectedRows.filter((selectedRows) => selectedRows.id !== props.id);
    });
  };

  const selectAllRows = (props) => {
    setSelectedTableRows(props);
  };
  const deselectAllRows = () => {
    setSelectedTableRows([]);
  };
  const searchOptionsDefault = orderBy
    ? { ...searchViewDefaultOptions, orderBy }
    : searchViewDefaultOptions;

  useEffect(() => {
    if (url) {
      if (constantFilters) {
        const NewFilters = {
          ...searchOptionsDefault.filters,
          ...constantFilters,
        };
        setSearchOptions({
          ...searchOptionsDefault,
          url,
          filters: NewFilters,
        });
      } else {
        setSearchOptions({
          ...searchOptionsDefault,
          url,
        });
      }
    }
  }, [url /* ,constantFilters */]);

  useEffect(() => {
    if (mutateProp && mutateValue !== mutateProp) {
      console.log('xx', mutateProp);
      mutate();
      setMutateValue(mutateProp);
    }
  }, [mutateProp]);

  return (
    <Grid container spacing={2} direction="column" key={url}>
      <Grid item container>
        {MiniReportComponent && data ? (
          <MiniReports MiniReportComponent={MiniReportComponent} swrOptions={swrOptions} />
        ) : null}
      </Grid>
      {/* <Grid item container justify="flex-end">
        <Grid item>
          {CreateFormComponent && <CreationDrawer Form={CreateFormComponent} />}
        </Grid>
      </Grid> */}
      <Grid container>
        <SearchTable
          customSearchBarComponents={customSearchBarComponents}
          columns={columns}
          childColumns={childColumns}
          filters={filters}
          constantFilters={constantFilters}
          showChannelOptions={showChannelOptions}
          exportOptions={exportOptions}
          selectedRows={selectedTableRows}
          openRows={openTableRows}
          supportsDownload={supportsDownload}
          select={select}
          selectTypes={selectTypes}
          toggleRow={toggleRow}
          selectRow={selectRow}
          deselectRow={deselectRow}
          selectAllRows={selectAllRows}
          deselectAllRows={deselectAllRows}
          carrierData={carrierData}
          url={url}
          searchData={data}
          me={me}
          swrOptions={swrOptions}
          selectedFilterValues={selectedFilterValues}
          setSelectedFilterValues={setSelectedFilterValues}
        />
      </Grid>
    </Grid>
  );
}

export default SearchView;
